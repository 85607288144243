import React from 'react';
import FormInput from "../../../shared/components/form/FormInput";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {AddressTypeOptions, NewClaimDto} from "../../../models/Claims/NewClaimDto";
import {UseFormRegister, UseFormWatch} from "react-hook-form/dist/types/form";
import FormInputSelect from "../../../shared/components/form/FormInputSelect";
import RadioOptionSelector from "../../../shared/components/form/RadioOptionSelector";
import { Col } from 'reactstrap';
import {dateBeforeToday, checkPdfFiletype, checkPdfFileSize} from "../../../shared/utils"
import { fetchGet } from "../../../shared/utils";

const SkipTracePackageOptions = ['Skip Trace Package 1', 'Skip Trace Package 2', 'Budget Based Skip Trace'];
const GenderOptions = ['Male', 'Female', 'Other'];
const VehicleTypeOptions = ['Car', 'Truck', 'Bus', 'Motorcycle'];

type Props = {
  errors: FieldErrors<NewClaimDto>;
  statePermissions: Array<string>;
  register: UseFormRegister<NewClaimDto>;
  watch: UseFormWatch<NewClaimDto>;
  disabledFields: Array<string>;
  isEditForm: Boolean;
}

function NewSkipTrace(props: Props) {
  const { errors, statePermissions, register, watch, disabledFields, isEditForm } = props;
  const [addAddress, getAddAddress] = React.useState(false);
  const budget = watch("skipTrace.package");
  const priorReportsId = watch("skipTrace.priorReportsDownload.searchDocumentUploadId");
  const otherDocsId = watch("skipTrace.otherDocumentsDownload.searchDocumentUploadId");

  const toggleAddAddress = (e) => {
    addAddress ? getAddAddress(false) : getAddAddress(true);
  }

  return (
    <>
      <FormInput 
        fieldName="insuredName" 
        title="Insured name" 
        type='text' 
        errors={errors} 
        validation={{
          required: "This field is required"
        }} 
        register={register} 
        disabled={disabledFields.includes('insuredName')}
      />
      <FormInput 
        fieldName="insuredRegistration" 
        title="Insured Registration" 
        type="text" 
        validation={{
          //minLength: {value: 4, message: "Must be 4 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('insuredRegistration')}
      />
      <FormInputSelect 
        fieldName="state" 
        options={statePermissions} 
        title="State" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('state')}
      />
      <FormInput 
        fieldName="incidentDate" 
        title="Accident Date" 
        type="date" 
        validation={{
          validate: {dateBeforeToday: (v:Date) => dateBeforeToday(v) || "The Date of Loss must be in the past"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentDate')}
      />
      <FormInput 
        fieldName="incidentLocation" 
        title="Accident Location" 
        type="text" 
        validation={{
          minLength: {value: 2, message: "Must be 2 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentLocation')}
      />
      <FormInput 
        fieldName="incidentDetails" 
        title="Accident Description" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentDetails')}
      />
      <RadioOptionSelector 
        fieldName="skipTrace.package" 
        title="Skip Trace Package" 
        options={SkipTracePackageOptions} 
        register={register} 
        errors={errors} 
        disabled={disabledFields.includes('skipTrace.package')}
      />
      {budget==='Budget Based Skip Trace' && 
        <FormInput 
          fieldName="skipTrace.budgetAmount" 
          title="Budget Amount" 
          type="text" 
          validation={{
            pattern: {value: /^[+-]?([0-9]*[.])?[0-9]+$/, message: "Must be a number"}, 
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('skipTrace.budgetAmount')}
        />
      }
      <FormInput 
        fieldName="skipTrace.searchReason" 
        title="Search Reason" 
        type="text" 
        validation={{
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.searchReason')}
      />
      <FormInput 
        fieldName="thirdPartyName" 
        title="Individuals Name" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('thirdPartyName')}
      />
      <RadioOptionSelector 
        fieldName="gender" 
        title="Gender" 
        options={GenderOptions} 
        register={register} 
        errors={errors} 
        disabled={disabledFields.includes('gender')}
      />
      <RadioOptionSelector 
        title="Address Type" 
        fieldName="thirdPartyAddress.addressType" 
        options={AddressTypeOptions} 
        register={register} 
        errors={errors} 
        disabled={disabledFields.includes('thirdPartyAddress.addressType')}
      />
      <FormInput 
        fieldName="thirdPartyAddress.address" 
        title="Last Known Address" 
        type="text" 
        validation={{
          required: "This field is required", 
          minLength: {value: 10, message: "Must be 10 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('thirdPartyAddress.address')}
      />
      <Col md={{ size: '4', offset: 2, order: 2 }}>
        <button type="button" className='additionalAddressLink' onClick={toggleAddAddress} disabled={disabledFields.includes('additionalAddress.address')}>{addAddress ? <span>-</span> : <span>+</span>} Add Additional Address</button>
      </Col>
      {addAddress && 
        <RadioOptionSelector 
          title="Address Type" 
          fieldName="additionalAddress.addressType" 
          options={AddressTypeOptions} 
          register={register} 
          errors={errors}
          disabled={disabledFields.includes('additionalAddress.addressType')}
        />
      }
      {addAddress && 
        <FormInput 
          fieldName="additionalAddress.address" 
          title="Additional Address" 
          type="text" 
          validation={{
            minLength: {value: 10, message: "Must be 10 or more characters"}, 
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('additionalAddress.address')}
        />
      }
      <FormInput 
        fieldName="dateOfBirth" 
        title="Date Of Birth" 
        type="date" 
        validation={{
          required: "This field is required"
        }}
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('dateOfBirth')}
      />
      <FormInput 
        fieldName="phoneNumber" 
        title="Phone Number" 
        type="text" 
        validation={{
          minLength: {value: 8, message: "Must be 8 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('phoneNumber')}
      />
      <FormInput 
        fieldName="skipTrace.email" 
        title="Email" 
        type="text" 
        validation={{
          minLength: {value: 8, message: "Must be 8 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.email')}
      />
      <FormInput 
        fieldName="debtAmount" 
        title="Debt Amount" 
        type="text" 
        validation={{
          pattern: {value: /^[+-]?([0-9]*[.])?[0-9]+$/, message: "Must be a number"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('debtAmount')}
      />
      <FormInput 
        fieldName="skipTrace.driversLicenceNumber" 
        title="Drivers Licence Number" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 to 8 characters"}, 
          maxLength: {value:8, message: "Must be 5 to 8 characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.driversLicenceNumber')}
      />
      <RadioOptionSelector 
        fieldName="vehicleType" 
        title="Vehicle Type" 
        options={VehicleTypeOptions} 
        register={register} 
        errors={errors} 
        disabled={disabledFields.includes('vehicleType')}
      />
      <FormInput 
        fieldName="vehicleMake" 
        title="Vehicle Make" 
        type="text" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('vehicleMake')}
      />
      <FormInput 
        fieldName="vehicleModel" 
        title="Vehicle Model" 
        type="text" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('vehicleModel')}
      />
      <FormInput 
        fieldName="vehicleYear" 
        title="Vehicle Year" 
        type="text" 
        validation={{
          pattern: {value: /^\d{4}$/, message: "Must be 4 digits"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('vehicleYear')}
      />
      <FormInput 
        fieldName="skipTrace.socialMedia" 
        title="Social Media" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.socialMedia')}
      />
      <FormInput 
        fieldName="skipTrace.profession" 
        title="Profession" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.profession')}
      />
      <FormInput 
        fieldName="skipTrace.lastKnownEmployment" 
        title="Last Known Employment" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.lastKnownEmployment')}
      />
      <FormInput 
        fieldName="skipTrace.nextOfKin" 
        title="Next of Kin" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.nextOfKin')}
      />
      <FormInput 
        fieldName="skipTrace.spousePartner" 
        title="Spouse or Partner" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.spousePartner')}
      />
      <FormInput 
        fieldName="skipTrace.alternativeContact" 
        title="Alternative Contact" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.alternativeContact')}
      />
      <FormInput 
        fieldName="skipTrace.vehicleOwnerName" 
        title="Vehicle Owner Name" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.vehicleOwnerName')}
      />
      <FormInput 
        fieldName="skipTrace.vehicleOwnerAddress" 
        title="Vehicle Owner Address" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"}, 
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.vehicleOwnerAddress')}
      />
      <FormInput 
        fieldName="skipTrace.lastContactDetails" 
        title="Last Contact Details" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.lastContactDetails')}
      />
      {!isEditForm && <FormInput 
        fieldName="skipTrace.priorReports" 
        title="Prior Reports" 
        type="file" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('skipTrace.priorReports')}
        validation={{
          validate: {
            checkPdfFiletype: (v:File) => checkPdfFiletype(v) || "You must supply a PDF file",
            checkPdfFileSize: (v:File) => checkPdfFileSize(v) || "The file must be less than 6MB"
          }
        }}
      />}
      {isEditForm && priorReportsId && 
      <p>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            fetchGet(`searches/getdocument/${priorReportsId}`,"application/json", "application/pdf",'blob')
              .then((response) => response.data)
              .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                  new Blob([blob])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `Prior_Reports_${priorReportsId}.pdf`
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
              });
          }}
        >
          Prior Reports Document
        </a>
      </p>
      }
      {!isEditForm && <FormInput 
        fieldName="skipTrace.otherDocuments" 
        title="Other Documents" 
        type="file" 
        errors={errors} 
        register={register}
        disabled={disabledFields.includes('skipTrace.otherDocuments')}
        validation={{
          validate: {
            checkPdfFiletype: (v:File) => checkPdfFiletype(v) || "You must supply a PDF file",
            checkPdfFileSize: (v:File) => checkPdfFileSize(v) || "The file must be less than 6MB"
          }
        }}
      />}
      {isEditForm && otherDocsId && 
      <p>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            fetchGet(`searches/getdocument/${otherDocsId}`, "application/json","application/pdf",'blob')
              .then((response) => response.data)
              .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                  new Blob([blob])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `Other_Documents_${otherDocsId}.pdf`
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
              });
          }}
        >
          Other Documents
        </a>
      </p>
      }
    </>
  );
}

export default NewSkipTrace;
