import React from "react";
import ResetPasswordForm from "./components/ResetPasswordForm";

const logoPath = `${process.env.PUBLIC_URL}/img/inspector-logo.png`;

const ResetPassword = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <div className="account__logo">
            <img src={logoPath} alt="inspector logo" />
          </div>
        </div>
        <h4 className="account__title">Reset Password</h4>
        <p>
          Set your password using the form below. Password requirements are:
        </p>{" "}
        <br></br>
        <ul>
          <li>minimum 10 characters</li>
          <li>
            include a mixture of upper and lower case letters, number and
            symbols
          </li>
          <li>can not be the same as your email</li>
        </ul>
        <br></br>
        <ResetPasswordForm />
      </div>
    </div>
  </div>
);

export default ResetPassword;
