import React, { useState } from 'react';
import {Path} from "react-hook-form";
import {UseFormRegister, UseFormReset, UseFormGetValues} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import FormInputWrapper from "./FormInputWrapper";

import { PairDto } from 'models/Misc/PairDto';

type Props<T> = {
  title: string;
  fieldName: Path<T>;
  register: UseFormRegister<T>;
  options: PairDto[];
  errors: FieldErrors<T>;
  validation?: any;
  disabled?: boolean;
  defaultValue?: PairDto;
  reset: UseFormReset<T>;
  getValues: UseFormGetValues<T>;
}

function SelectNew<T>(props: Props<T>) {

  const {title, fieldName, options, register, validation, errors, disabled, defaultValue, reset, getValues} = props;

  //the errors object comes as an object with the sub form objects, which need to be flattened so the correct fieldName is used to access it later
  let errorsTransposed={...errors};
  for (const subForm of ['licence','transcript','police','registration','skipTrace','fieldCall','thirdPartyAddress','additionalAddress']) {
    if(errors[subForm])
    {
      for (const [key, value] of Object.entries(errors[subForm])) {
        errorsTransposed[subForm+'.'+key]=value;
      }
      delete errorsTransposed[subForm];
    }
  }
  const optionsWithDefault = [defaultValue, ...options].map(option => {
    return (<option key={fieldName+option.value} value={option.value}>{option.label}</option>);
  })

  const onChange = (event) => {
    var values = getValues();
    var type = typeof values[fieldName as string];
    values[fieldName as string] = type ==='number' ? parseInt(event.target.value) : event.target.value;
    reset(values);
  }

  return (
    <FormInputWrapper title={title} error={errorsTransposed[fieldName as string]}>
      <select 
        className="react-select" 
        defaultValue='0' 
        {...register(fieldName, {...validation})} 
        disabled={disabled}
        onChange={onChange}
        >
        {optionsWithDefault}
      </select>
    </FormInputWrapper>
  );
}

export default SelectNew;