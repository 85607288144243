import React, {PropsWithChildren} from 'react';
import {Col} from "reactstrap";

type Props = {
  title: string;
  error: {
    message?: string
  };
} & PropsWithChildren

function FormInputWrapper(props: Props) {

  const {title, error, children} = props;

  return (
    <Col md="6">
      <div className="form__form-group">
        <span className="form__form-group-label">{title}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
            {children}
            {error &&
                <span className="form__form-group-error">{error.message}</span>
            }
          </div>
        </div>
      </div>
    </Col>
  );
}

export default FormInputWrapper;