import React, { useEffect, } from 'react';
import {useForm} from "react-hook-form";
import { Navigate, useParams } from 'react-router';
import {useMutation} from "react-query";
import { Store } from "react-notifications-component";
import { Col, Spinner} from "reactstrap";
import axios from "axios";

import TitleWrapper from "../../../shared/components/TitleWrapper";
import StyledForm from "../../../shared/components/form/StyledForm";
import FormInput from "../../../shared/components/form/FormInput";
import { fetchGet, boolToInt } from "../../../shared/utils";
import { Permissions, } from "../../../shared/constants";
import { UserGroupDto } from 'models/UserGroupDto';
import PermissionsTable from 'shared/components/form/PermissionsTable';

function EditUserGroup() {
  const {id, clientId} = useParams();
  const {register, handleSubmit, formState: {errors}, reset, getValues, setValue} = useForm<UserGroupDto>({defaultValues: {}, mode: 'all'});
  const [loading, setLoading] = React.useState(true);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const [redirectLink, setRedirectLink] = React.useState("/pages/usergroups");
  const notificationMessageSuccess = id ? "User group has been updated." : "User group has been created.";
  const notificationMessageError = id ? "User group could not be updated." : "New user group could not be created";
  const mutation = useMutation([], (data: UserGroupDto) => {
      if(id) {
        return axios.post('usergroups/update', data,)
      }
      data.clientId = parseInt(clientId);
      return axios.post('usergroups/create', data,)
    },
    {
      onSuccess: (resp) => {
        Store.addNotification({
          title: "User Groups",
          message: notificationMessageSuccess,
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
        setShouldRedirect(true)
        fetchUserGroup(resp.data.id);
      },
      onError: (resp) => {
        Store.addNotification({
          title: "User Groups",
          message: notificationMessageError,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      }
    })

  //gets
  const fetchUserGroup = async (userGroupId) => {
    fetchGet(`usergroups/${userGroupId}`)
      .then((response) => response.data)
      .then((resData) => {
        reset(resData)
      })
      .catch((error) => {
          console.log("error getting data from server " + error);
      });
      setLoading(false);
  }

  //posts
  const postForm = async (data: UserGroupDto) => {
      //ensure permissions are integers and not booleans
      var permissions = Permissions();
      boolToInt(data, permissions);
      mutation.mutate(data);
  }

  //initialisation
  useEffect(() => {
    if(id) {
      fetchUserGroup(id);
    } else {
      setLoading(false);
    }
  }, []);

  //UI
  if(shouldRedirect) {
    return <Navigate to={redirectLink}/>;
  }
  return(
    <TitleWrapper title="User Groups">
      {loading && <Spinner className="spinner"/>}
      {!loading && <StyledForm 
        heading={id ? "Edit User Group": "New User Group"}
        subHeading={(id ? "edit user group details using" : "create a new user group using") + " the form below"}
        submit={handleSubmit(postForm)}
        isSaving={mutation.isLoading}
        errorMessage={mutation.isError && mutation.error instanceof Error && mutation.error.message}
        >
          <fieldset >
            <FormInput 
              fieldName="name" 
              title="Name" 
              type='text' 
              errors={errors} 
              validation={{
                minLength: {value: 2, message: "Must be 2 or more characters"}, 
                required: "This field is required"
              }} 
              register={register}
            />
            </fieldset>
            <Col md="6">
              <PermissionsTable
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
              <button
                className="btn btn-primary account__btn account__btn--small"
                type='submit'
              >
              {id && 'Update User Group'}
              {!id && 'Create New User Group'}
              </button>
            </Col>
        </StyledForm>
      }       
    </TitleWrapper>
  );
}

export default EditUserGroup;