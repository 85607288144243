// 1 - License
// 2 - Registration
// 3 - Police
// 4 - Skip Trace
// 5 - Field Call
// 6 - Transcript
// 7 - Fire

import {NewTranscriptDto} from "./NewTranscriptDto";
import {NewFieldCallDto} from "./NewFieldCallDto";
import {NewSkipTraceDto} from "./NewSkipTraceDto";
import {NewLicenceDto} from "./NewLicenceDto";
import {NewRegistrationDto} from "./NewRegistrationDto";
import {NewPoliceDto} from "./NewPoliceDto";
import {NewFireReportDto} from "./NewFireReportDto";
import { number } from "prop-types";

type SearchType = 'Licence' | 'Registration' | 'Police' | 'Skip Trace' | 'Field Call' | 'Transcript' | 'Fire';
export const StateOptions = ['NSW', 'QLD', 'VIC', 'WA', 'SA', 'TAS', 'NT', 'ACT'];
export type State = typeof StateOptions[number];

export const AddressTypeOptions = ['Business', 'Residential'];
export type AddressType = typeof AddressTypeOptions[number];

export const ThirdPartyOmissionOptions = [
  'Third party abandoned the scene and a witness provided the Third Party Registration Number',
  'Third Party refused to provide details',
  'Incorrect details provided',
  'Insured party obtained the registration number of the Third Party Vehicle, the name and telephone number of the driver of the Third Party Vehicle',
  'Insured party only obtained the registration number of the Third Party Vehicle',
  'Other'
];
export const ThirdPartyOmissionOptionsReverse = {
  'Third party abandoned the scene and a witness provided the Third Party Registration Number':'Abandoned',
  'Third Party refused to provide details':'Refused',
  'Incorrect details provided':'Incorrect',
  'Insured party obtained the registration number of the Third Party Vehicle, the name and telephone number of the driver of the Third Party Vehicle':'InsuredAll',
  'Insured party only obtained the registration number of the Third Party Vehicle':'InsuredReg',
  'Other':'Other',
}
export type ThirdPartyOmission = typeof ThirdPartyOmissionOptions[number];

type Gender = 'Male' | 'Female' | 'Other';

type Address = {
  address: string;
  addressType: AddressType;
}
//type SkipTracePackage = 'Skip Trace Package 1' | 'Skip Trace Package 2' | 'Budget Based Skip Trace';
type VehicleType = 'Car' | 'Truck' | 'Bus' | 'Motorcycle';
//type ClaimType = 'Motor Vehicle Claim' | 'Property Damage Claim';

export type NewClaimDto = {
  searchType: SearchType;
  claimNumber: string;
  insuredName: string;
  incidentDate: string;
  insuredRegistration: string;
  state: State;
  incidentLocation: string;
  incidentDetails: string;
  gender: Gender;
  thirdPartyName: string; //changed field name to this, from ThirdPartyLicenceHolderName and IndividualsName
  thirdPartyAddress: Address; //changed field name to this, from FieldCall->PrimaryAddressToAttend and SkipTrace->LastKnownAddress
  thirdPartyRegistration: string;
  thirdPartyLicenceNumber: string;
  thirdPartyOmissionReason: ThirdPartyOmission;
  thirdPartyOmissionOther: string;
  dateOfBirth: Date;
  phoneNumber: string;
  debtAmount: number;
  additionalAddress: Address;
  vehicleType: VehicleType;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string; 
  transcript: NewTranscriptDto;
  fire: NewFireReportDto;
  fieldCall: NewFieldCallDto;
  skipTrace: NewSkipTraceDto;
  licence: NewLicenceDto;
  registration: NewRegistrationDto;
  police: NewPoliceDto;
  enquiryConfirmation: Boolean;
  policeConfirmation: Boolean;
  licenceRegistrationConfirmation: Boolean;
  partyName: string;
  conflictInsuredConfirmation: Boolean;
  subscriptionSkipTraceDBConfirmation: Boolean;
  externalSearchConfirmation: Boolean;
  phoneWrittenContactConfirmation: Boolean;
  motorwebDBSearchConfirmation: Boolean;
  allReasonableInquiriesConfirmation: Boolean;


}

//type Transcript = {
//  id: number;
//  requestingConsultant: string;
//  consultantOnCall: string;
//  otherPartyOnCall: string;
//  callDate: Date;
//  numberOnCall: number;
//  audioFileUrl: File;
//  location: string;
//  externalReference: string;
//  notes: string;
//  textFileUrl: File;
//}
//
//type FieldCall = {
//  currentContactNumbers: Boolean;
//  demandBalanceOutstanding: Boolean;
//  propertyCondition: Boolean;
//  confirmResidency: Boolean;
//  confirmInsurance: Boolean;
//  completeDocuments: Boolean;
//  amountToCollect: string;
//  dateForPayment: Date;
//  debtOwedTo: string;
//  documentsToComplete: File;
//}
//
//type SkipTrace= {
//  package: SkipTracePackage;
//  budgetAmount: number;
//  searchReason: string;
//  email: string;
//  driversLicenceNumber: string;
//  vehicleRegistration: string;
//  socialMedia: string;
//  profession: string;
//  lastKnownEmployment: string;
//  nextOfKin: string;
//  spousePartner: string;
//  alternativeContact: string;
//  vehicleOwnerName: string;
//  vehicleOwnerAddress: Address;
//  lastContactDetails: string;
//  priorReports: File;
//  otherDocuments: File;
//}
//
//type Licence = {
//  //thirdPartyLicenceNumber: string;
//}
//
//type PoliceSearch = {
//  insuredAddress: string;
//  authorityLetter: File;
//  claimType: ClaimType;
//  policeReportNumber: string;
//  detailsAttendingPolice: string;
//}
//
//type Registration = {
//  vehicleShape: string;
//  vehicleColour: string;
//  //thirdPartyRegistration: string;
//}
