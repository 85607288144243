import React from 'react';
import FormInput from "../../../shared/components/form/FormInput";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {NewClaimDto, ThirdPartyOmissionOptions} from "../../../models/Claims/NewClaimDto";
import {UseFormRegister, UseFormWatch} from "react-hook-form/dist/types/form";
import FormInputSelect from "../../../shared/components/form/FormInputSelect";
import RadioOptionSelector from "../../../shared/components/form/RadioOptionSelector";
import ConfirmationBox from 'shared/components/form/ConfirmationBox';
import {dateBeforeToday} from "../../../shared/utils"
const VehicleTypeOptions = ['Car', 'Truck', 'Bus', 'Motorcycle'];

type Props = {
  errors: FieldErrors<NewClaimDto>;
  statePermissions: Array<string>;
  register: UseFormRegister<NewClaimDto>;
  watch: UseFormWatch<NewClaimDto>;
  disabledFields: Array<string>;
  setValue: any;
}

function NewRegistration(props: Props) {
  const { errors, statePermissions, register, setValue, watch, disabledFields } = props;
  const state = watch("state");
  const thirdPartyOmmission = watch("thirdPartyOmissionReason");
  //certain checkboxes are now "linked"
  const extraConfirmationBoxToggle = (e) => {
    const checkboxState=e.target.checked;
    setValue("conflictInsuredConfirmation", checkboxState);
    setValue("subscriptionSkipTraceDBConfirmation", checkboxState);
    setValue("externalSearchConfirmation", checkboxState);
    setValue("phoneWrittenContactConfirmation", checkboxState);
    setValue("motorwebDBSearchConfirmation", checkboxState);
    setValue("allReasonableInquiriesConfirmation", checkboxState);
  }
  return (
    <>
      <FormInput 
        fieldName="insuredName" 
        title="Insured name" 
        type='text' 
        errors={errors} 
        validation={{
          required: "This field is required" 
        }} 
        register={register} 
        disabled={disabledFields.includes('insuredName')}
      />
      <FormInput 
        fieldName="insuredRegistration" 
        title="Insured Registration" 
        type="text" 
        validation={{
          //minLength: {value: 4, message: "Must be 4 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('insuredRegistration')}
      />
      <FormInputSelect 
        fieldName="state" 
        options={statePermissions} 
        title="State" 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }} 
        disabled={disabledFields.includes('state')}
      />
      <FormInput 
        fieldName="incidentDate" 
        title="Accident Date" 
        type="date" 
        errors={errors} 
        validation={{
          validate: {dateBeforeToday: (v:Date) => dateBeforeToday(v) || "The Date of Loss must be in the past"},
          required: "This field is required"
        }} 
        register={register} 
        disabled={disabledFields.includes('incidentDate')}
      />
      <FormInput 
        fieldName="incidentLocation" 
        title="Accident Location" 
        type="text" 
        validation={{
          minLength: {value: 2, message: "Must be 2 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentLocation')}
      />
      <FormInput 
        fieldName="incidentDetails" 
        title="Accident Description" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentDetails')}
      />
      {state === 'VIC' &&
        <FormInputSelect 
          fieldName="thirdPartyOmissionReason" 
          options={ThirdPartyOmissionOptions}
          title="Reason third party details were not obtained at the time of incident" 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required"
          }} 
          disabled={disabledFields.includes('thirdPartyOmissionReason')}
        />
      }
      {state === 'VIC' && thirdPartyOmmission === 'Other' && 
        <FormInput 
          fieldName="thirdPartyOmissionOther" 
          title="OTHER Reason third party details were not obtained at the time of incident" 
          type="text" 
          validation={{
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('thirdPartyOmissionOther')}
        />
      }
      <FormInput 
        fieldName="thirdPartyName" 
        title="Third Party Licence Holder Name" 
        type="text" 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('thirdPartyName')}
      />
      <FormInput 
        fieldName="thirdPartyRegistration" 
        title="Third Party Registration" 
        type="text" 
        validation={{
          //minLength: {value: 4, message: "Must be 4 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('thirdPartyRegistration')}
      />
      <RadioOptionSelector 
        fieldName="vehicleType" 
        title="Vehicle Type" 
        options={VehicleTypeOptions} 
        register={register} 
        errors={errors} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('vehicleType')}
      />
      {state === 'ACT' && 
        <FormInput 
          fieldName="vehicleMake" 
          title="Vehicle Make" 
          type="text" 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('vehicleMake')}
        />
      }
      {state === 'ACT' && 
        <FormInput 
          fieldName="vehicleModel" 
          title="Vehicle Model" 
          type="text" 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('vehicleModel')}
        />
      }
      {state === 'ACT' && 
        <FormInput 
          fieldName="vehicleYear" 
          title="Vehicle Year" 
          type="text" 
          validation={{
            pattern: {value: /^\d{4}$/, message: "Must be 4 digits"},
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('vehicleYear')}
        />
      }
      {state === 'ACT' && 
        <FormInput 
          fieldName="registration.vehicleShape" 
          title="Vehicle Shape" 
          type="text" 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('registration.vehicleShape')}
        />
      }
      {state === 'ACT' && 
        <FormInput 
          fieldName="registration.vehicleColour" 
          title="Vehicle Colour" 
          type="text" 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('registration.vehicleColour')}
        />
      }
      {state === 'VIC' &&
        <ConfirmationBox 
          fieldName="conflictInsuredConfirmation" 
          title="We have conducted searches of Our claims handling platform for any conflicts and to ensure the registered vehicle and/or the licence holder is not insured by Us." 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required"
          }}
          onChange={extraConfirmationBoxToggle}
          disabled={disabledFields.includes('conflictInsuredConfirmation')}
        />
      }
      {state === 'VIC' &&
        <ConfirmationBox
          fieldName="subscriptionSkipTraceDBConfirmation"
          title="In circumstances where the name of the third party is known, we have conducted searches of subscription based online databases which We have access to."
          errors={errors}
          register={register}
          validation={{
            required: "This field is required"
          }}
          onChange={extraConfirmationBoxToggle}
          disabled={disabledFields.includes('subscriptionSkipTraceDBConfirmation')}
        />
      }
      {state === 'VIC' &&
        <ConfirmationBox
          fieldName="externalSearchConfirmation"
          title="We have conducted external searches, of Google and/or the electronic White Pages and/or electronic Yellow Pages and/or Reverse Search Australia and/or and ABN business search."
          errors={errors}
          register={register}
          validation={{
            required: "This field is required"
          }}
          onChange={extraConfirmationBoxToggle}
          disabled={disabledFields.includes('externalSearchConfirmation')}
        />
      }
      {state === 'VIC' &&
        <ConfirmationBox
          fieldName="phoneWrittenContactConfirmation"
          title="In circumstances where We have the contact details of the third party, We have attempted via telephone or written correspondence (if available) to make contact with the Third Party."
          errors={errors}
          register={register}
          validation={{
            required: "This field is required"
          }}
          onChange={extraConfirmationBoxToggle}
          disabled={disabledFields.includes('phoneWrittenContactConfirmation')}
        />
      }
      {state === 'VIC' &&
        <ConfirmationBox
          fieldName="motorwebDBSearchConfirmation"
          title="We have conducted a search of the free registration check available through Vic Roads, or used the MotorWeb database, which confirms that the motor vehicle operated by the third party is registered in Victoria, as well as the make and model of the car."
          errors={errors}
          register={register}
          validation={{
            required: "This field is required"
          }}
          onChange={extraConfirmationBoxToggle}
          disabled={disabledFields.includes('motorwebDBSearchConfirmation')}
        />
      }
      {state === 'VIC' &&
        <ConfirmationBox
          fieldName="allReasonableInquiriesConfirmation"
          title="We believe we have conducted all reasonable inquiries, and we have been unable to ascertain the description of a person sufficiently for the purpose of commencing recovery or a proceeding in the Court against that person."
          errors={errors}
          register={register}
          validation={{
            required: "This field is required"
          }}
          onChange={extraConfirmationBoxToggle}
          disabled={disabledFields.includes('allReasonableInquiriesConfirmation')}
        />
      }
      <ConfirmationBox 
        fieldName="licenceRegistrationConfirmation" 
        title="As per the State and Territory regulatory requirement, I confirm that CCSG Legal Pty Ltd are instructed to obtain a registration and/or licence search and I agree that the information obtained will only be used for the purpose of commencing or defending legal proceedings in relation to a motor traffic accident and no other purpose." 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('licenceRegistrationConfirmation')}
      />
    </>
  );
}

export default NewRegistration;
