import React, { Component } from "react";
import { Spinner } from "reactstrap";
// eslint-disable-next-line import/no-extraneous-dependencies
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import { Store } from "react-notifications-component";
import IdleTimer from "../IdleTimer";
import { QueryClient, QueryClientProvider } from "react-query";
import CacheBuster from "react-cache-buster";
import packageInfo from "../../../package.json";

const queryClient = new QueryClient();

class App extends Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    //had issues where the loading screen just wont go away on page reload,
    // so set these to opposite of what they were to try and prevent the loading screen from glitching
    this.state = {
      loading: false,
      loaded: true,
    };
  }

  componentDidMount() {
    window.addEventListener("load", () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    const isProduction = process.env.NODE_ENV === "production";
    const { version } = packageInfo;
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={/* isProduction */ true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Spinner className="spinner" />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ScrollToTop>
              {!loaded && (
                <div className={`load${loading ? "" : " loaded"}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path
                        fill="#4ce1b6"
                        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div>
                <IdleTimer
                  ref={(ref) => {
                    this.idleTimer = ref;
                  }}
                  timeout={1000 * 60 * 15}
                  onActive={this.handleOnActive}
                  onIdle={this.handleOnIdle}
                  onAction={this.handleOnAction}
                  debounce={250}
                />
                <Router />
              </div>
            </ScrollToTop>
          </BrowserRouter>
        </QueryClientProvider>
      </CacheBuster>
    );
  }

  handleOnAction(event) {
    //console.log('user did something', event)
  }

  handleOnActive(event) {
    //console.log('user is active', event)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle() {
      //console.log('user is idle', event)
      //console.log('last active', this.idleTimer.getLastActiveTime())

      localStorage.clear("jwt_access");
      localStorage.clear("jwt_refresh");

    Store.addNotification({
      title: "Inspector",
      message:
        "Your session has been idle for more than 15 minutes.  Please log in again.",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
    });
  }
}

export default App;
