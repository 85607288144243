import React from 'react';
import FormInput from "../../../shared/components/form/FormInput";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {UseFormRegister, UseFormWatch} from "react-hook-form/dist/types/form";
import FormInputSelect from 'shared/components/form/FormInputSelect';
import RadioOptionSelector from "../../../shared/components/form/RadioOptionSelector";
import CheckBoxSelector from "../../../shared/components/form/CheckBoxSelector";
import {AddressTypeOptions, NewClaimDto } from "../../../models/Claims/NewClaimDto";
import { Col } from 'reactstrap';
import ConfirmationBox from 'shared/components/form/ConfirmationBox';
import {dateBeforeToday, checkPdfFiletype, checkPdfFileSize} from "../../../shared/utils"
import { fetchGet } from "../../../shared/utils";
const GenderOptions = ['Male', 'Female', 'Other'];

type Props = {
  errors: FieldErrors<NewClaimDto>;
  statePermissions: Array<string>;
  register: UseFormRegister<NewClaimDto>;
  watch: UseFormWatch<NewClaimDto>;
  disabledFields: Array<string>;
  isEditForm: Boolean;
}

function NewFieldCall(props: Props) {
  const { errors, statePermissions, register, watch, disabledFields, isEditForm } = props;
  const completeDocs = watch("fieldCall.completeDocuments");
  const confirmResidency = watch("fieldCall.confirmResidency");
  const demandOutstanding = watch("fieldCall.demandBalanceOutstanding");
  const CompleteDocsId = watch("fieldCall.documentsToCompleteDownload.searchDocumentUploadId");
  const [addAddress, getAddAddress] = React.useState(false);
  return (
    <>
      <FormInput 
        fieldName="insuredName" 
        title="Insured name" 
        type='text' 
        errors={errors} 
        validation={{
          required: "This field is required" 
        }} 
        register={register} 
        disabled={disabledFields.includes('insuredName')}
      />
      <FormInput 
        fieldName="insuredRegistration" 
        title="Insured Registration" 
        type="text" 
        validation={{
          //minLength: {value: 4, message: "Must be 4 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('insuredRegistration')}
      />
      <FormInputSelect 
        fieldName="state" 
        options={statePermissions} 
        title="State" 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('state')}
      />
      <FormInput 
        fieldName="incidentDate" 
        title="Accident Date" 
        type="date" 
        errors={errors} 
        validation={{
          validate: {dateBeforeToday: (v:Date) => dateBeforeToday(v) || "The Date of Loss must be in the past"},
          required: "This field is required"
        }}
        register={register} 
        disabled={disabledFields.includes('incidentDate')}
      />
      <FormInput 
        fieldName="incidentLocation" 
        title="Accident Location" 
        type="text" validation={{
          minLength: {value: 2, message: "Must be 2 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentLocation')}
      />
      <FormInput 
        fieldName="incidentDetails" 
        title="Accident Description" 
        type="text" validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentDetails')}
      />
      <FormInput 
        fieldName="thirdPartyName" 
        title="Third Party name" 
        type='text' 
        validation={{
          //minLength: {value: 5, message: "Must be 5 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('thirdPartyName')}
      />
      <RadioOptionSelector 
        fieldName="gender" 
        title="Gender" 
        options={GenderOptions} 
        register={register} 
        errors={errors} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('gender')}
      />
      <RadioOptionSelector 
        title="Address Type" 
        fieldName="thirdPartyAddress.addressType" 
        options={AddressTypeOptions} 
        register={register} 
        errors={errors} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('thirdPartyAddress.addressType')}
      />
      <FormInput 
        fieldName="thirdPartyAddress.address" 
        title="Primary Address to Attend" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register}
        disabled={disabledFields.includes('thirdPartyAddress.address')}
      />
      <Col md={{ size: '4', offset: 2, order: 2 }}>
        <button type="button" className='additionalAddressLink' onClick={(e) => getAddAddress(true)} disabled={disabledFields.includes('additionalAddress.address')}>+ Add Additional Address</button>
      </Col>
      {addAddress && 
        <RadioOptionSelector 
          title="Address Type" 
          fieldName="additionalAddress.addressType" 
          options={AddressTypeOptions} 
          register={register} 
          errors={errors} 
          validation={{
            required: "This field is required"
          }}
          disabled={disabledFields.includes('additionalAddress.addressType')}
        />
      }
      {addAddress && 
        <FormInput 
          fieldName="additionalAddress.address" 
          title="Additional Address" 
          type="text" 
          validation={{
            minLength: {value: 10, message: "Must be 10 or more characters"},
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('additionalAddress.address')}
        />
      }
      <FormInput 
        fieldName="dateOfBirth" 
        title="Date Of Birth" 
        type="date" 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('dateOfBirth')}
      />
      <FormInput 
        fieldName="phoneNumber" 
        title="Phone Number" 
        type="text" 
        validation={{
          minLength: {value: 8, message: "Must be 8 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('phoneNumber')}
      />
      <CheckBoxSelector 
        fieldName="fieldCall.currentContactNumbers" 
        title="Obtain current contact numbers" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('fieldCall.currentContactNumbers')}
      />
      <CheckBoxSelector 
        fieldName="fieldCall.demandBalanceOutstanding" 
        title="Make demand for balance outstanding" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('fieldCall.demandBalanceOutstanding')}
      />
      <CheckBoxSelector 
        fieldName="fieldCall.propertyCondition" 
        title="Make note of assets / property condition" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('fieldCall.propertyCondition')}
      />
      <CheckBoxSelector 
        fieldName="fieldCall.confirmResidency" 
        title="Confirm residency / collect outstanding / confirm contact details" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('fieldCall.confirmResidency')}
      />
      <CheckBoxSelector 
        fieldName="fieldCall.confirmInsurance" 
        title="Confirm Insurance details" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('fieldCall.confirmInsurance')}
      />
      <CheckBoxSelector 
        fieldName="fieldCall.completeDocuments" 
        title="Complete Documents (attach documents)" 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('fieldCall.completeDocuments')}
      />
      {(confirmResidency || demandOutstanding) && 
        <FormInput 
          fieldName="debtAmount" 
          title="Debt Amount" 
          type="text" 
          validation={{
            pattern: {value: /^[+-]?([0-9]*[.])?[0-9]+$/, message: "Must be a number"},
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('debtAmount')}
        />
      }
      {(confirmResidency || demandOutstanding) && 
        <FormInput 
          fieldName="fieldCall.amountToCollect" 
          title="Amount To Collect" 
          type="text" 
          validation={{
            pattern: {value: /^[+-]?([0-9]*[.])?[0-9]+$/, message: "Must be a number"},
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('fieldCall.amountToCollect')}
        />
      }
      {(confirmResidency || demandOutstanding) && 
        <FormInput 
          fieldName="fieldCall.dateForPayment" 
          title="Date for Payment" 
          type="date" 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required"
          }}
          disabled={disabledFields.includes('fieldCall.dateForPayment')}
        />
      }
      {(confirmResidency || demandOutstanding) && 
        <FormInput 
          fieldName="fieldCall.debtOwedTo" 
          title="Debt Owed To" 
          type="text" 
          validation={{
            // minLength: {value: 5, message: "Must be 5 or more characters"},
            required: "This field is required"
          }} 
          errors={errors} 
          register={register} 
          disabled={disabledFields.includes('fieldCall.debtOwedTo')}
        />
      }
      {completeDocs && !isEditForm && 
        <FormInput 
          fieldName="fieldCall.documentsToComplete" 
          title="Documents to Complete" 
          type="file" 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required",
            validate: {
              checkPdfFiletype: (v:File) => checkPdfFiletype(v) || "You must supply a PDF file",
              checkPdfFileSize: (v:File) => checkPdfFileSize(v) || "The file must be less than 6MB"
            }
          }}
          disabled={disabledFields.includes('fieldCall.documentsToComplete')}
        />
      }
      {isEditForm && CompleteDocsId && 
      <p>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            fetchGet(`searches/getdocument/${CompleteDocsId}`, "application/json","application/pdf",'blob')
              .then((response) => response.data)
              .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                  new Blob([blob])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `Documents_to_Complete_${CompleteDocsId}.pdf`
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
              });
          }}
        >
          Documents To Complete
        </a>
      </p>
      }
      <ConfirmationBox 
        fieldName="enquiryConfirmation" 
        title="We confirm that the attendance is in accordance with the requirements of the Debt Collection guidelines." 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('enquiryConfirmation')}
      />
      <Col md={{ size: '6' }}>
      You should only make face-to-face contact when such contact is necessary and reasonable. In such cases, ASIC/ACCC recommends that you do not make more than one face-to-face contact with a debtor per month (if contact with the debtor actually takes place).
      </Col>
    </>
  );
}

export default NewFieldCall;
