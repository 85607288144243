import React from "react";
import { Col, Container, Row } from "reactstrap";
import NewUserGroupForm from "./components/NewUserGroupForm";

const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">User Groups</h3>
      </Col>
    </Row>
    <Row>
      <NewUserGroupForm />
    </Row>
  </Container>
);

export default ExamplePage;
