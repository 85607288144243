import React, { PureComponent } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { Spinner, Table } from "reactstrap";
import { Redirect } from "react-router-dom";
import { Store } from "react-notifications-component";
import CheckBox from "../../../../shared/components/form/CheckBox";
import { fetchPost } from "../../../../shared/utils";
import {Navigate} from "react-router";

function ValidationMessage(props) {
  if (!props.valid) {
    return <span className="form__form-group-error">{props.message}</span>;
  }
  return null;
}

class NewUserGroupForm extends PureComponent {
  constructor() {
    super();

    //todo: fix with proper router solution using props
    let clientIdUrl = window.location.href.split("/");
    // let actionName = clientIdUrl.pop();
    let clientId = clientIdUrl.pop();

    this.state = {
      formSubmitFailed: false,
      formSubmitMessage: "",
      shouldRedirect: false,
      formValid: false,
      isLoading: false,
      clientId: clientId === "pages" ? 0 : clientId,
      name: "",
      nameValid: true,
      nameErrorMsg: "",
      isAdmin: false,
    };
  }

  validateForm = () => {
    const { nameValid } = this.state;
    this.setState({
      formValid: nameValid,
    });
  };

  updateName = (name) => {
    this.setState({ name }, this.validateName);
  };

  updateIsAdmin = (isAdmin) => {
    this.setState({ isAdmin }, this.validateIsAdmin);
  };

  validateName = () => {
    const { name } = this.state;
    let isValid = true;
    let errorMsg = this.state.nameErrorMsg;

    if (!name || name.length <= 2) {
      isValid = false;
      errorMsg = "Please enter a valid User Group Name";
    }

    this.setState(
      { nameValid: isValid, nameErrorMsg: errorMsg },
      this.validateForm
    );
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.isLoading) {
      return null;
    }

    this.setState({
      isLoading: true,
    });

    this.validateName();

    if (!this.state.formValid) {
      this.setState({
        isLoading: false,
      });
      return null;
    }
    fetchPost("usergroups/create", {
      name: this.state.name,
      licenceNSW: document.getElementById("licenceNSW").checked ? 1 : 0,
      licenceVIC: document.getElementById("licenceVIC").checked ? 1 : 0,
      licenceQLD: document.getElementById("licenceQLD").checked ? 1 : 0,
      licenceWA: document.getElementById("licenceWA").checked ? 1 : 0,
      licenceSA: document.getElementById("licenceSA").checked ? 1 : 0,
      licenceTAS: document.getElementById("licenceTAS").checked ? 1 : 0,
      licenceACT: document.getElementById("licenceACT").checked ? 1 : 0,
      licenceNT: document.getElementById("licenceNT").checked ? 1 : 0,
      registrationNSW: document.getElementById("registrationNSW").checked
        ? 1
        : 0,
      registrationVIC: document.getElementById("registrationVIC").checked
        ? 1
        : 0,
      registrationQLD: document.getElementById("registrationQLD").checked
        ? 1
        : 0,
      registrationWA: document.getElementById("registrationWA").checked
        ? 1
        : 0,
      registrationSA: document.getElementById("registrationSA").checked
        ? 1
        : 0,
      registrationTAS: document.getElementById("registrationTAS").checked
        ? 1
        : 0,
      registrationACT: document.getElementById("registrationACT").checked
        ? 1
        : 0,
      registrationNT: document.getElementById("registrationNT").checked
        ? 1
        : 0,
      policeNSW: document.getElementById("policeNSW").checked ? 1 : 0,
      policeVIC: document.getElementById("policeVIC").checked ? 1 : 0,
      policeQLD: document.getElementById("policeQLD").checked ? 1 : 0,
      policeWA: document.getElementById("policeWA").checked ? 1 : 0,
      policeSA: document.getElementById("policeSA").checked ? 1 : 0,
      policeTAS: document.getElementById("policeTAS").checked ? 1 : 0,
      policeACT: document.getElementById("policeACT").checked ? 1 : 0,
      policeNT: document.getElementById("policeNT").checked ? 1 : 0,
      skipTraceNSW: document.getElementById("skipTraceNSW").checked ? 1 : 0,
      skipTraceVIC: document.getElementById("skipTraceVIC").checked ? 1 : 0,
      skipTraceQLD: document.getElementById("skipTraceQLD").checked ? 1 : 0,
      skipTraceWA: document.getElementById("skipTraceWA").checked ? 1 : 0,
      skipTraceSA: document.getElementById("skipTraceSA").checked ? 1 : 0,
      skipTraceTAS: document.getElementById("skipTraceTAS").checked ? 1 : 0,
      skipTraceACT: document.getElementById("skipTraceACT").checked ? 1 : 0,
      skipTraceNT: document.getElementById("skipTraceNT").checked ? 1 : 0,
      fieldCallNSW: document.getElementById("fieldCallNSW").checked ? 1 : 0,
      fieldCallVIC: document.getElementById("fieldCallVIC").checked ? 1 : 0,
      fieldCallQLD: document.getElementById("fieldCallQLD").checked ? 1 : 0,
      fieldCallWA: document.getElementById("fieldCallWA").checked ? 1 : 0,
      fieldCallSA: document.getElementById("fieldCallSA").checked ? 1 : 0,
      fieldCallTAS: document.getElementById("fieldCallTAS").checked ? 1 : 0,
      fieldCallACT: document.getElementById("fieldCallACT").checked ? 1 : 0,
      fieldCallNT: document.getElementById("fieldCallNT").checked ? 1 : 0,
      transcriptNSW: document.getElementById("transcriptNSW").checked ? 1 : 0,
      transcriptVIC: document.getElementById("transcriptVIC").checked ? 1 : 0,
      transcriptQLD: document.getElementById("transcriptQLD").checked ? 1 : 0,
      transcriptWA: document.getElementById("transcriptWA").checked ? 1 : 0,
      transcriptSA: document.getElementById("transcriptSA").checked ? 1 : 0,
      transcriptTAS: document.getElementById("transcriptTAS").checked ? 1 : 0,
      transcriptACT: document.getElementById("transcriptACT").checked ? 1 : 0,
      transcriptNT: document.getElementById("transcriptNT").checked ? 1 : 0,
    })
      .then((response) => response.data)
      .then((data) => {
        if (!data.id) {
          Store.addNotification({
            title: "User Groups",
            message: "New user group could not be created",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 10000,
              showIcon: true,
            },
          });

          this.setState({
            isLoading: false,
          });
        } else {
          // clear current state
          this.setState({
            shouldRedirect: true,
          });
        }
      })
      .catch((error) => {
        Store.addNotification({
          title: "Users",
          message: "New user group could not be created.",
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });

        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    if (this.state.shouldRedirect) {
      let redirectLink = "/pages/usergroups";
      return <Navigate to={redirectLink} />;
    }

    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">New User Group</h5>
              <h5 className="subhead">
                create a new user group using the form below
              </h5>
            </div>
            <Col md={6}>
              <form className="form form--horizontal">
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                      <input
                        name="Name"
                        type="text"
                        placeholder="user group name"
                        value={this.state.name}
                        onChange={(e) => this.updateName(e.target.value)}
                      />
                      <ValidationMessage
                        valid={this.state.nameValid}
                        message={this.state.nameErrorMsg}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p>
                    User Group Search Permissions. Select the State and Searches
                    users in this group can access.
                    <br />
                  </p>
                </div>

                <Table>
                  <thead>
                    <tr>
                      <th>Search</th>
                      <th>NSW</th>
                      <th>VIC</th>
                      <th>QLD</th>
                      <th>WA</th>
                      <th>SA</th>
                      <th>TAS</th>
                      <th>ACT</th>
                      <th>NT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Licence</td>
                      <td>
                        <CheckBox
                          name="licenceNSW"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceVIC"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceQLD"
                          label=""
                          disabled
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceWA"
                          label=""
                          disabled
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceSA"
                          label=""
                          disabled
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceTAS"
                          label=""
                          disabled
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceACT"
                          label=""
                          disabled
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="licenceNT"
                          label=""
                          disabled
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Registration</td>
                      <td>
                        <CheckBox
                          name="registrationNSW"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationVIC"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationQLD"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationWA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationSA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationTAS"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationACT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="registrationNT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Police</td>
                      <td>
                        <CheckBox
                          name="policeNSW"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeVIC"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeQLD"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeWA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeSA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeTAS"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeACT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="policeNT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Skip Trace</td>
                      <td>
                        <CheckBox
                          name="skipTraceNSW"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceVIC"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceQLD"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceWA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceSA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceTAS"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceACT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="skipTraceNT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Field Call</td>
                      <td>
                        <CheckBox
                          name="fieldCallNSW"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallVIC"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallQLD"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallWA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallSA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallTAS"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallACT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="fieldCallNT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Transcript</td>
                      <td>
                        <CheckBox
                          name="transcriptNSW"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptVIC"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptQLD"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptWA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptSA"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptTAS"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptACT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                      <td>
                        <CheckBox
                          name="transcriptNT"
                          label=""
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <button
                  className="btn btn-primary account__btn account__btn--small"
                  onClick={(e) => {
                    this.handleFormSubmit(e);
                  }}
                >
                  {this.state.isLoading ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#FFFFFF",
                      }}
                    />
                  ) : (
                    "Create New User Group"
                  )}
                </button>
              </form>
            </Col>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default NewUserGroupForm;
