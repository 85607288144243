import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import {
  isAuthenticated,
  fetchGet,
  fetchPost,
  logout,
} from "../../shared/utils";
import { Navigate, Outlet } from "react-router";
import { Col, Spinner } from "reactstrap";
import jwt_decode from "jwt-decode";

const PrivateRoute = () => {
  const [authenticated, setAuthenticated] = React.useState(false);
  const [resetToken, setResetToken] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    isAuthenticated();
  }, []);
  const isAuthenticated = () => {
    if (localStorage.getItem("jwt_access") !== null) {
      var decoded = jwt_decode(localStorage.getItem("jwt_access"));
      fetchPost(`users/reauthenticate`, {
        email: decoded.email,
      })
        .then((response) => response.data)
        .then((resData) => {
          if (resData.resettoken) {
            logout();
            setResetToken(resData.resettoken);
            setLoading(false);
          } else {
            setAuthenticated(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("error getting data from server " + error);
          setAuthenticated(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  if (loading) {
    return <Spinner className="spinner" />;
  } else {
    if (resetToken) {
      return <Navigate to={"/resetpassword/" + resetToken} />;
    } else if (authenticated) {
      return <Outlet />;
    }
    window.location.href = '/log_in';
  }
};

export default PrivateRoute;
