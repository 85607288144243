import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react';
import App from './containers/App/App';
import ReactDOM from 'react-dom/client';
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_ROOT_API_URL;

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("jwt_access");
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem("jwt_refresh");
        const accessToken = localStorage.getItem("jwt_access");
        
        //if the call was to refresh token and we get an unauthorized or bad response, we can assume the user isn't logged in or their refresh token is wrong, just send them to the login page
        if ((error.response.status === 401 || error.response.status === 400) && originalRequest.url === 'users/refreshtoken') {
            return window.location.href = '/log_in';
        }
        
        //if the call resulted in unauthorised and the call wasn't for a refresh token, update the access token and try again
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            
            return axios
            .post('users/refreshtoken', {
                refreshToken: refreshToken,
                accessToken: accessToken
            })
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('jwt_access', res.data.accessToken);
                    if (res.data.refreshToken) {
                        localStorage.setItem('jwt_refresh', res.data.refreshToken);
                    }
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwt_access");
                    return axios(originalRequest);
                }
            })
        }
        //if something hasn't been done by now, just reject the request
        return Promise.reject(error)
    }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
