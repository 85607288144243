import axios from "axios";

const TOKEN_KEY = "jwt_access";
const REFRESH_KEY = "jwt_refresh";

export const loginSuccess = (jwtObj) => {
  localStorage.setItem(TOKEN_KEY, jwtObj.token);
  localStorage.setItem(REFRESH_KEY, jwtObj.refreshToken);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_KEY);
  window.location.href = "/log_in";
};

export const isAuthenticated = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const passwordValidation = (password) => {
  if (!password) {
    return "please enter a valid password";
  } else if (password.length < 10) {
    return "password must be greater than 10 characters";
  } else if (!password.match(/[a-z]+/)) {
    return "password must contain a lowercase letter";
  } else if (!password.match(/[A-Z]+/)) {
    return "password must contain a uppercase letter";
  } else if (!password.match(/[0-9]+/)) {
    return "password must contain a number";
  } else if (!password.match(/.[ !"#$%&'()*+,-./:;<=>?@\[\\\]\^_`{|}~]/)) {
    return "password must contain a special character";
  }
};

export const setDelay = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const dateBeforeToday = (myDate) => {
  //if the date passed in is blank, don't worry about testing it
  if (myDate === "" || myDate === "Invalid date") {
    return true;
  }
  const dateToCheck = new Date(myDate);
  const today = new Date();
  return dateToCheck <= today;
};

export const checkPdfFiletype = (fileObj) => {
  if (fileObj.length > 0) {
    return fileObj[0].type === "application/pdf";
  }
  return true;
};

export const checkPdfFileSize = (fileObj) => {
  if (fileObj.length > 0) {
    const file = fileObj[0];
    if (file.size) {
      const size = fileObj[0].size;
      return size <= PDFFILESIZE;
    }
  }
  return false;
};

export const isolateFileExtension = (fileObj) => {
  const lastDot = fileObj[0].name.lastIndexOf(".");
  return fileObj[0].name.substring(lastDot + 1);
};

export const checkAudioFileType = (fileObj, allowedFiles = []) => {
  if (fileObj.length > 0) {
    if (allowedFiles.length == 0) {
      allowedFiles = AUDIOFILES;
    }
    const ext = isolateFileExtension(fileObj);

    return allowedFiles.includes(ext.toUpperCase());
  }
  return false;
};

export const checkAudioFileSize = (fileObj) => {
  if (fileObj.length > 0) {
    const file = fileObj[0];
    if (file.size) {
      const size = fileObj[0].size;
      return size <= AUDIOFILESIZE;
    }
  }
  return false;
};

export const checkTextFileType = (fileObj, allowedFiles = []) => {
  if (fileObj.length > 0) {
    if (allowedFiles.length == 0) {
      allowedFiles = TEXTFILES;
    }
    const ext = isolateFileExtension(fileObj);

    return allowedFiles.includes(ext.toUpperCase());
  }
  return false;
};

export const fetchGet = (
  url,
  accept = "application/json",
  contentType = "application/json",
  responseType = null
) => {
  if (responseType) {
    return axios.get(process.env.REACT_APP_ROOT_API_URL + url, {
      responseType: responseType,
      headers: {
        Accept: accept,
        "Content-Type": contentType,
      },
    });
  } else {
    return axios.get(process.env.REACT_APP_ROOT_API_URL + url, {
      headers: {
        Accept: accept,
        "Content-Type": contentType,
      },
    });
  }
};

export const fetchPost = (
  url,
  body,
  accept = "json",
  contentType = "application/json"
) => {
  const headers = {};

  if (accept) {
    headers["Accept"] = "application/" + accept;
  }
  if (contentType) {
    headers["Content-Type"] = contentType;
  }
  return axios.post(process.env.REACT_APP_ROOT_API_URL + url, body, headers);
};

export const boolToInt = (data, keys) => {
  for (var key in data) {
    var objFound = keys.find((x) => x.name === key);
    if (objFound) {
      if (data[key] === true) {
        data[key] = 1;
      }
      if (data[key] === false) {
        data[key] = 0;
      }
    }
  }
  return data;
};

export const AUDIOFILESIZE = 262144000;
export const PDFFILESIZE = 6000000;

export const TEXTFILES = [
  "DOC",
  "DOCX",
  "HTML",
  "HTM",
  "ODT",
  "PDF",
  "XLS",
  "XLSX",
  "ODS",
  "PPT",
  "PPTX",
  "TXT",
];

export const AUDIOFILES = [
  "3GP",
  "AA",
  "AAC",
  "AAX",
  "ACT",
  "AIFF",
  "ALAC",
  "AMR",
  "APE",
  "AU",
  "AWB",
  "DSS",
  "DVF",
  "FLAC",
  "GSM",
  "IKLA",
  "IVS",
  "M4A",
  "M4B",
  "M4P",
  "MMF",
  "MP3",
  "MPC",
  "MSV",
  "NMF",
  "OGG",
  "OPUS",
  "RA",
  "RAW",
  "RF64",
  "SLN",
  "TTA",
  "VOC",
  "VOX",
  "WAV",
  "WMA",
  "WV",
  "WEBM",
  "8SVX",
  "CDA",
  "WEBM",
  "MKV",
  "FLV",
  "FLV",
  "VOB",
  "OGV",
  "OGG",
  "DRC",
  "GIF",
  "GIFV",
  "MNG",
  "AVI",
  "MTS",
  "M2TS",
  "TS",
  "MOV",
  "QT",
  "WMV",
  "YUV",
  "RM",
  "RMVB",
  "VIV",
  "ASF",
  "AMV",
  "MP4",
  "M4P",
  "M4V",
  "MPG",
  "MP2",
  "MPEG",
  "MPE",
  "MPV",
  "MPG",
  "MPEG",
  "M2V",
  "M4V",
  "SVI",
  "3GP",
  "3G2",
  "MXF",
  "ROQ",
  "NSV",
  "FLV",
  "F4V",
  "F4P",
  "F4A",
  "F4B",
];
