import React, { PropsWithChildren } from "react";
import { Card, CardBody, Col, Spinner } from "reactstrap";
import "./StyledForm.css";
import { Store } from "react-notifications-component";

type Props = {
  heading: string;
  subHeading: string;
  submit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  isSaving: boolean;
  errorMessage: string | undefined;
} & PropsWithChildren;

function StyledForm(props: Props) {
  const { submit, heading, subHeading, children, isSaving, errorMessage } =
    props;

  if (errorMessage) {
    Store.addNotification({
      title: "Save failed",
      message: errorMessage,
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
    });
  }

  return (
    <form className="form form--horizontal" onSubmit={submit}>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{heading}</h5>
              <h5 className="subhead">{subHeading}</h5>
            </div>
            {children}
          </CardBody>
        </Card>
        {isSaving && <Spinner className="spinner" />}
      </Col>
    </form>
  );
}

export default StyledForm;
