import React, { useEffect, } from 'react';
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import { Store } from "react-notifications-component";
import { Spinner} from "reactstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";

import TitleWrapper from "../../../shared/components/TitleWrapper";
import StyledForm from "../../../shared/components/form/StyledForm";
import FormInput from "../../../shared/components/form/FormInput";
import { fetchGet, boolToInt } from "../../../shared/utils";
import { Permissions, } from "../../../shared/constants";
import { LocalStorageJwtDto } from 'models/Misc/LocalStorageJwtDto';
import { UserDto } from 'models/UserDto';

function UserDetails() {
    const {register, reset, handleSubmit, formState: {errors}} = useForm<UserDto>({defaultValues: {}, mode: 'all'});
    const [loading, setLoading] = React.useState(true);

    let id = "";
    if (localStorage.getItem("jwt_access") !== null) {
      var decoded = jwt_decode(localStorage.getItem("jwt_access"));

      id = (decoded as LocalStorageJwtDto).unique_name;
    }
    const mutation = useMutation([], (data: UserDto) => {
        return axios.post('users/mydetails', data,)
      },
      {
        onSuccess: (resp) => {
          Store.addNotification({
            title: "Clients",
            message: "User could not be updated",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 10000,
              showIcon: true,
            },
          });
          fetchUser(resp.data.id);
        },
        onError: (resp) => {
          Store.addNotification({
            title: "Clients",
            message: "User details updated",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 10000,
              showIcon: true,
            },
          });
        }
      })

    //gets
    const fetchUser = async (userId) => {
        fetchGet(`users/${userId}`)
        .then((response) => response.data)
        .then((resData) => {
            reset(resData);
        })
        .catch((error) => {
            console.log("error getting data from server " + error);
        });
        setLoading(false);
    };

    //posts
    const postForm = async (data: UserDto) => {
        //ensure permissions are integers and not booleans
        var permissions = Permissions();
        boolToInt(data, permissions);
        mutation.mutate(data);
    }

    //initialisation
    useEffect(() => {
        fetchUser(id)
    }, []);

    //UI
    return(
      <TitleWrapper title="My Details">
        {loading && <Spinner className="spinner"/>}
        {!loading && <StyledForm 
          heading="User Details"
          subHeading="your user details are displayed below" 
          submit={handleSubmit(postForm)}
          isSaving={mutation.isLoading}
          errorMessage={mutation.isError && mutation.error instanceof Error && mutation.error.message}
          >
            <fieldset disabled={true}>
              <FormInput 
                fieldName="firstName" 
                title="FirstName" 
                type='text' 
                errors={errors} 
                validation={{
                  minLength: {value: 2, message: "Must be 2 or more characters"}, 
                  required: "This field is required"
                }} 
                register={register}
              />
              <FormInput 
                fieldName="lastName" 
                title="Lastname" 
                type='text' 
                errors={errors} 
                validation={{
                  minLength: {value: 2, message: "Must be 2 or more characters"}, 
                  required: "This field is required"
                }} 
                register={register}
              />
              <FormInput 
                fieldName="email" 
                title="Email (username)" 
                type='text' 
                errors={errors} 
                validation={{
                  minLength: {value: 8, message: "Must be 8 or more characters"}, 
                  required: "This field is required"
                }} 
                register={register}
              />
              <FormInput 
                fieldName="userGroup" 
                title="UserGroup" 
                type='text' 
                errors={errors} 
                register={register}
              />
            </fieldset>
          </StyledForm>
        }       
      </TitleWrapper>
    );
}

export default UserDetails;