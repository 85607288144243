import React from "react";
import { Col, Container, Row } from "reactstrap";
import UsersDataTable from "./components/UsersDataTable";

const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Users</h3>
      </Col>
    </Row>
    <Row>
      <UsersDataTable />
    </Row>
  </Container>
);

export default ExamplePage;
