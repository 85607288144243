import React from 'react';
import {Path} from "react-hook-form";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import FormInputWrapper from "./FormInputWrapper";

type Props<T> = {
  title: string;
  fieldName: Path<T>;
  register: UseFormRegister<T>;
  validation?: any; 
  errors: FieldErrors<T>;
  type: 'text' | 'date' | 'number' | 'file' | 'datetime-local',
  disabled?: boolean
  onchange?: any
}

function FormInput<T>(props: Props<T>) {
  const {title, fieldName, register, validation, errors, type, disabled = false, onchange} = props;
  
  //the errors object comes as an object with the sub form objects, which need to be flattened so the correct fieldName is used to access it later
  let errorsTransposed={...errors};
  for (const subForm of ['licence','transcript','police','registration','skipTrace','fire','fieldCall','thirdPartyAddress','additionalAddress']) {
    if(errors[subForm])
    {
      for (const [key, value] of Object.entries(errors[subForm])) {
        errorsTransposed[subForm+'.'+key]=value;
      }
      delete errorsTransposed[subForm];
    }
  }
  return (
    <FormInputWrapper title={title} error={errorsTransposed[fieldName as string]}>
      <input
        {...register(fieldName, {...validation})} type={type} placeholder={title} disabled={disabled} onChange={onchange}
      />
    </FormInputWrapper>
  );
}

export default FormInput;