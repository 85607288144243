export const StateOptions = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT',];

export const SearchTypes = {
    licence: 'Licence', 
    registration: 'Registration', 
    police: 'Police', 
    skipTrace: 'Skip Trace', 
    fieldCall:'Field Call', 
    transcript: 'Transcript',
    fire: 'Fire',
};

export const DisabledPermissions = [
    'licenceQLD',
    'licenceWA',
    'licenceSA',
    'licenceTAS',
    'licenceACT',
    'licenceNT',
];

export const Permissions = (search = null, state = null) => {
    var permissions = [];
    if(search) {
        StateOptions.forEach(option => permissions.push({id:search + "_" + option, name: search + option}));
        return permissions;
    }
    if(state) {
        for (var key in SearchTypes){
            permissions.push({id:key + "_" + state, name: key + state});
        }
        return permissions;
    }
    for (var key in SearchTypes){
        StateOptions.forEach(option => permissions.push({id:key + "_" + option, name: key + option}));
    }
    return permissions;
}