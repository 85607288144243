import React, { useEffect, useState } from "react";
import TitleWrapper from "../../../shared/components/TitleWrapper";
import StyledForm from "../../../shared/components/form/StyledForm";
import { useForm } from "react-hook-form";
import { NewClaimDto } from "../../../models/Claims/NewClaimDto";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, ButtonToolbar, Table } from "reactstrap";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";
import MagnifyPlusIcon from "mdi-react/MagnifyPlusOutlineIcon";

import FormInput from "../../../shared/components/form/FormInput";
import { useParams } from "react-router";
import { fetchGet } from "../../../shared/utils";

function EditTranscriptClaim() {
  const { id } = useParams();
  const [parties, setParties] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NewClaimDto>({ defaultValues: {}, mode: "all" });

  const fetchClaim = async (claimId) => {
    fetchGet(`claims/${claimId}`)
      .then((response) => response.data)
      .then((resData) => {
        reset(resData);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
  };

  const fetchParties = async (claimId) => {
    //get the parties and search details
    fetchGet(`claims/${claimId}/parties`)
      .then((response) => response.data)
      .then((resData) => {
        setParties(resData);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
  };

  useEffect(() => {
    fetchClaim(id);
    fetchParties(id);
  }, []);

  return (
    <TitleWrapper title="Claims">
      <StyledForm
        heading=""
        subHeading=""
        submit={handleSubmit(() => {})}
        isSaving={false}
        errorMessage={""}
      >
        <FormInput
          fieldName="claimNumber"
          title="Claim Number"
          type="text"
          disabled={true}
          errors={errors}
          register={register}
        />
      </StyledForm>
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-sm-12 col-md-9">
              <div className="card__title">
                <h5 className="bold-text">Claim Parties</h5>
                <h5 className="subhead">
                  modify existing parties and searches from here
                </h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="float-right">
                <Link to={`/pages/claimparty/${id}`}>
                  <button className="icon btn btn-success">
                    <AccountPlusIcon /> Add New Party
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Party Type</th>
                <th>Name</th>
                <th>Searches</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {parties.map(function (val, i) {
                return (
                  <tr key={"parties" + i}>
                    <th scope="row">{i + 1}</th>
                    <td>{val.partyType}</td>
                    <td>{val.name}</td>
                    <td>
                      {val.partySearches.map(function (search, k) {
                        var searchStatus: string =
                          search.searchStatus === "In Progress"
                            ? "Submitted"
                            : search.searchStatus;
                        if (search.searchType === "Transcript") {
                          searchStatus = search.searchStatus;
                        }
                        return (
                          <Link
                            key={`partysearches${k}`}
                            to={`/pages/searches/${search.searchType}/${search.id}`}
                          >
                            <span className="badge badge-primary">
                              <span>
                                {search.searchType} - <i>{searchStatus}</i>
                              </span>
                            </span>
                            &nbsp;
                          </Link>
                        );
                      })}
                    </td>
                    <td>
                      {/* TODO: Fix? fire report new search*/}
                      {val.id != 0 && (
                        <Link to={"/pages/claimpartysearch/" + val.id}>
                          <MagnifyPlusIcon /> New Search
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </TitleWrapper>
  );
}

export default EditTranscriptClaim;
