import React from "react";
import { Route } from "react-router-dom";
import Layout from "../Layout/index";

import LogIn from "../LogIn/index";
import ResetPasswordRequest from "../ResetPasswordRequest";
import ResetPassword from "../ResetPassword";
import ClaimList from "../Claims/List/index";

import Clients from "../Clients/List/index";
import EditClient from "../Clients/Edit/EditClient";
import Users from "../Users/List/index";
import UserGroups from "../UserGroups/List/index";
import NewUserGroup from "../UserGroups/New/index";
import PrivateRoute from "./PrivateRoute";
import EmailConfirmation from "../EmailConfirmation/index";
import EditUser from "../Users/Edit/EditUser";
import UserDetails from "../Users/Details/UserDetails";
import EditUserGroup from "../UserGroups/Edit/EditUserGroup";
import { Outlet, Routes } from "react-router";
import NewTranscriptClaim from "../Claims/NewTranscriptClaim/NewTranscriptClaim";
import EditTranscriptClaim from "../Claims/EditTranscriptClaim/EditTranscriptClaim";
import GeneralSettings from "../General Settings/GeneralSettings";

const WrappedRoutes = () => {
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Outlet />
      </div>
    </div>
  );
};

const Router = () => (
  <div className="theme-light ltr-support" dir="ltr">
    <div className="wrapper">
      <main>
        <Routes>
          <Route exact path="/" element={<LogIn />} />
          <Route exact path="/log_in" element={<LogIn />} />
          <Route
            exact
            path="/resetpasswordrequest"
            element={<ResetPasswordRequest />}
          />
          <Route
            exact
            path="/emailconfirmation/:code"
            element={<EmailConfirmation />}
          />
          <Route
            exact
            path="/resetpassword/:code"
            element={<ResetPassword />}
          />
          <Route path="/pages" element={<PrivateRoute />}>
            <Route path="/pages" element={<WrappedRoutes />}>
              <Route
                path="newclaim"
                element={<NewTranscriptClaim key={Math.random()} />}
              />
              <Route path="editclaim/:id" element={<EditTranscriptClaim />} />
              <Route
                path="claimparty/:claimId"
                element={<NewTranscriptClaim key={Math.random()} />}
              />
              <Route
                path="claimpartysearch/:partyId"
                element={<NewTranscriptClaim key={Math.random()} />}
              />
              <Route path="claimlist" element={<ClaimList />} />
              <Route path="clients/:clientId/newuser" element={<EditUser />} />
              <Route path="clients" element={<Clients />} />
              <Route path="newclient" element={<EditClient />} />
              <Route path="editclient/:id" element={<EditClient />} />
              <Route path="users" element={<Users />} />
              <Route path="usergroups" element={<UserGroups />} />
              <Route path="edituser/:id" element={<EditUser />} />
              <Route path="editclientuser/:id" element={<EditUser />} />
              <Route path="userdetails" element={<UserDetails />} />
              <Route path="newusergroup" element={<NewUserGroup />} />
              <Route path="editusergroup/:id" element={<EditUserGroup />} />
              <Route
                path="searches/:searchId"
                element={<NewTranscriptClaim key={Math.random()} />}
              />
              <Route
                path="searches/:sType/:searchId/"
                element={<NewTranscriptClaim key={Math.random()} />}
              />
              <Route path="generalsettings" element={<GeneralSettings />} />
            </Route>
          </Route>
          <Route path="*" element={<LogIn />} />
        </Routes>
      </main>
    </div>
  </div>
);

export default Router;
