import React from 'react';
import FormInput from "../../../shared/components/form/FormInput";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {NewClaimDto} from "../../../models/Claims/NewClaimDto";
import {UseFormRegister, UseFormWatch} from "react-hook-form/dist/types/form";
import FormInputSelect from "../../../shared/components/form/FormInputSelect";
import {dateBeforeToday, checkPdfFiletype, checkPdfFileSize} from "../../../shared/utils";
type Props = {
  errors: FieldErrors<NewClaimDto>;
  statePermissions: Array<string>;
  register: UseFormRegister<NewClaimDto>;
  watch: UseFormWatch<NewClaimDto>;
  disabledFields: Array<string>;
}

//const ClaimTypeOptions = ['Motor Vehicle Claim', 'Property Damage Claim'];

function NewFire(props: Props) {
  const { errors, statePermissions, register, watch, disabledFields } = props;
  const state = watch("state");
  return (
    <>
      <FormInput 
        fieldName="fire.partyName" 
        title="Party Name (Party shown on Report)" 
        type='text' 
        errors={errors} 
        validation={{
          required: "This field is required"
        }} 
        register={register} 
        disabled={disabledFields.includes('fire.partyName')}
      />
      <FormInputSelect 
        fieldName="state" 
        options={statePermissions} 
        title="State" 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('state')}
      />
      <FormInput 
        fieldName="incidentDate" 
        title="Date of Incident" 
        type="date" 
        errors={errors} 
        register={register} 
        validation={{
          validate: {dateBeforeToday: (v:Date) => dateBeforeToday(v) || "The Incident Date must be in the past"},
          required: "This field is required"
        }}
        disabled={disabledFields.includes('incidentDate')}
      />
      <FormInput 
        fieldName="incidentLocation" 
        title="Location of Incident" 
        type="text" 
        validation={{
          minLength: {value: 2, message: "Must be 2 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentLocation')}
      />
      <FormInput 
        fieldName="incidentDetails" 
        title="Details of Incident / Type of Incident" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentDetails')}
      />
      <FormInput 
        fieldName="fire.reportNumber" 
        title="Report Number (if known)" 
        type="text" 
        validation={{
          minLength: {value: 5, message: "Must be 5 or more characters"},
        }} 
        errors={errors} 
        register={register}
        disabled={disabledFields.includes('fire.reportNumber')} 
      />
      {(state === 'NSW' || state === 'QLD' || state === 'WA' || state === 'ACT') && 
        <FormInput 
          fieldName="fire.authorityLetter" 
          title="Authority Letter" 
          type="file" 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required",
            validate: {
              checkPdfFiletype: (v:File) => checkPdfFiletype(v) || "You must supply a PDF file",
              checkPdfFileSize: (v:File) => checkPdfFileSize(v) || "The file must be less than 6MB"
            }
          }}
          disabled={disabledFields.includes('fire.authorityLetter')}
        />
      }
    </>
  );
}

export default NewFire;
