import React, {PropsWithChildren} from 'react';
import {Col, Container, Row} from "reactstrap";

type Props = {
  title: string
} & PropsWithChildren

function TitleWrapper(props: Props) {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{props.title}</h3>
        </Col>
      </Row>
      <Row>
        {props.children}
      </Row>
    </Container>
  );
}

export default TitleWrapper;