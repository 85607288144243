import React, { useEffect } from "react";
import FormInput from "../../../shared/components/form/FormInput";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { NewClaimDto } from "../../../models/Claims/NewClaimDto";
import { NewTextFileDto } from "../../../models/Misc/NewTextFileDto";
import {
  UseFormRegister,
  UseFormGetValues,
  UseFormReset,
  UseFormWatch,
} from "react-hook-form/dist/types/form";
import {
  dateBeforeToday,
  checkAudioFileType,
  checkAudioFileSize,
  checkTextFileType,
} from "../../../shared/utils";
import { fetchGet } from "../../../shared/utils";
import axios from "axios";
import { useMutation } from "react-query";
import { Store } from "react-notifications-component";

type Props = {
  errors: FieldErrors<NewClaimDto>;
  register: UseFormRegister<NewClaimDto>;
  disabledFields: Array<string>;
  getValues: UseFormGetValues<NewClaimDto>;
  isEditForm: Boolean;
  reset: UseFormReset<NewClaimDto>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFetch: any;
  fetch: any;
  watch: UseFormWatch<NewClaimDto>;
};

function NewTranscript(props: Props) {
  const {
    errors,
    register,
    disabledFields,
    getValues,
    isEditForm,
    reset,
    setLoading,
    setFetch,
    fetch,
    watch,
  } = props;
  const [acceptedAudioFiles, setAcceptedAudioFiles] = React.useState([]);
  const [acceptedTextFiles, setAcceptedTextFiles] = React.useState([]);
  const transcript = getValues("transcript");
  const transcript_id = transcript === undefined ? "" : transcript["id"];
  const textFileId = transcript === undefined ? "" : transcript["textFilePath"];
  const textFileOriginalFilename =
    transcript === undefined ? "" : transcript["textFileOriginalFilename"];
  const textFileType =
    transcript === undefined ? "" : transcript["textFileMimeType"];
  const textUploadWatch = watch("transcript.textFileUrl");
  var textUploadFile;
  const uploadMutation = useMutation(
    [],
    (data: NewTextFileDto) => {
      setLoading(true);
      return axios.post(
        "searches/" + transcript_id + "/texttranscriptupload",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: (resp) => {
        let notificationTitle = "Transcript text file uploaded successfully.";
        Store.addNotification({
          title: notificationTitle,
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 0,
            showIcon: true,
          },
        });
        setLoading(false);
        reset();
        setFetch(fetch + 1);
      },
      onError: () => {
        let notificationTitle = "Transcript text file could not be uploaded.";
        Store.addNotification({
          title: notificationTitle,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 0,
            showIcon: true,
          },
        });
        setLoading(false);
      },
    }
  );
  const deleteMutation = useMutation(
    [],
    () => {
      setLoading(true);
      return axios.post("searches/" + transcript_id + "/deletetexttranscript");
    },
    {
      onSuccess: (resp) => {
        let notificationTitle = "Transcript text file deleted successfully.";
        Store.addNotification({
          title: notificationTitle,
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 0,
            showIcon: true,
          },
        });
        setLoading(false);
        reset();
        setFetch(fetch + 1);
      },
      onError: () => {
        let notificationTitle = "Transcript text file could not be deleted.";
        Store.addNotification({
          title: notificationTitle,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 0,
            showIcon: true,
          },
        });
        setLoading(false);
      },
    }
  );

  const fetchAcceptedAudioFiles = async () => {
    await fetchGet("claims/acceptedaudiofiles")
      .then((response) =>
        setAcceptedAudioFiles(response.data.map((v) => v.name || "") || [])
      )
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
  };
  const fetchAcceptedTextFiles = async () => {
    await fetchGet("claims/acceptedtextfiles")
      .then((response) =>
        setAcceptedTextFiles(response.data.map((v) => v.name || "") || [])
      )
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
  };

  useEffect(() => {
    fetchAcceptedAudioFiles();
    fetchAcceptedTextFiles();
  }, []);

  return (
    <>
      <FormInput 
        fieldName="insuredName" 
        title="Insured name" 
        type='text' 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('insuredName')}
      />
      <FormInput
        fieldName="transcript.requestingConsultant"
        title="Requesting Consultant"
        type="text"
        validation={
          {
            //minLength: {value: 5, message: "Must be 5 or more characters"},
            //required: "This field is //required"
          }
        }
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.requestingConsultant")}
      />
      <FormInput
        fieldName="incidentDate"
        title="Date of Loss"
        type="date"
        validation={{
          validate: {
            dateBeforeToday: (v: Date) =>
              dateBeforeToday(v) || "The Date of Loss must be in the past",
          },
          //required: "This field is //required"
        }}
        errors={errors}
        register={register}
        disabled={disabledFields.includes("incidentDate")}
      />
      <FormInput
        fieldName="transcript.consultantOnCall"
        title="Consultant on call"
        type="text"
        validation={
          {
            //minLength: {value: 5, message: "Must be 5 or more characters"},
            //required: "This field is //required"
          }
        }
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.consultantOnCall")}
      />
      <FormInput
        fieldName="transcript.otherPartyOnCall"
        title="Other party on call"
        type="text"
        validation={
          {
            //minLength: {value: 5, message: "Must be 5 or more characters"},
            //required: "This field is //required"
          }
        }
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.otherPartyOnCall")}
      />
      <FormInput
        fieldName="transcript.callDate"
        title="Call Date"
        type="datetime-local"
        validation={
          {
            //required: "This field is //required"
          }
        }
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.callDate")}
      />
      <FormInput
        fieldName="transcript.numberOnCall"
        title="Number on call"
        type="number"
        validation={{
          pattern: { value: /^\d*$/, message: "Must be a whole number" },
          //required: "This field is //required"
        }}
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.numberOnCall")}
      />
      <FormInput
        fieldName="transcript.location"
        title="Where/Location"
        type="text"
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.location")}
      />
      <FormInput
        fieldName="transcript.externalReference"
        title="External Reference"
        type="text"
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.externalReference")}
      />
      <FormInput
        fieldName="transcript.notes"
        title="Notes"
        type="text"
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.notes")}
      />
      <FormInput
        fieldName="transcript.audioFileUrl"
        title="Audio/Video file"
        type="file"
        validation={{
          validate: {
            checkAudioFileType: (v: File) => {
              if (!checkAudioFileType(v, acceptedAudioFiles)) {
                return "This file type is not accepted. Please choose another.";
              }
              if (!checkAudioFileSize(v)) {
                return "File is too large. Please reduce file size to below 250Mb.";
              }
              return true;
            },
          },
          required: "This field is required",
        }}
        errors={errors}
        register={register}
        disabled={disabledFields.includes("transcript.audioFileUrl")}
      />
      {!textFileId && isEditForm && (
        <div>
          <FormInput
            fieldName="transcript.textFileUrl"
            title="Transcript text file"
            type="file"
            errors={errors}
            register={register}
            validation={{
              validate: {
                checkTextFileType: (v: File) => {
                  if (!checkTextFileType(v, acceptedTextFiles)) {
                    return "This file type is not accepted. Please choose another.";
                  }
                  return true;
                },
              },
              required: "This field is required",
            }}
            onchange={(e) => {
              const file = e.target.files[0];
              if (file) {
                if (!checkTextFileType([file], acceptedTextFiles)) {
                  return false;
                }
                textUploadFile = file;
                uploadMutation.mutate({ textTranscriptFileUpload: file });
              }
            }}
          />
        </div>
      )}
      {textFileId && isEditForm && (
        <p
          style={{
            marginBottom: "2%",
          }}
        >
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              fetchGet(
                `searches/gettexttranscript/${transcript_id}`,
                "application/json",
                `${textFileType}`,
                "blob"
              )
                .then((response) => response.data)
                .then((blob) => {
                  // Create blob link to download
                  const url = window.URL.createObjectURL(
                    new Blob([blob], { type: `${textFileType}` })
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    `${transcript.textFileOriginalFilename}`
                  );
                  // Append to html link element page
                  document.body.appendChild(link);

                  // Start download
                  link.click();

                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                });
            }}
          >
            Download Text File
          </a>
          <span
            style={{
              marginLeft: "2%",
            }}
          ></span>
          <a
            style={{
              color: "red",
            }}
            href=""
            onClick={(e) => {
              e.preventDefault();
              if (window.confirm("Confirm deletion of transcript text file?")) {
                //fetchPost(`searches/${transcript.id}/deletetexttranscript`, `${transcript.textFileMimeType}`);
                deleteMutation.mutate();
              }
            }}
          >
            Delete Text File
          </a>
        </p>
      )}
    </>
  );
}

export default NewTranscript;
