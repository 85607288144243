import React, { Component } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import classNames from "classnames";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";

class Layout extends Component {
  state = {
    sidebarShow: false,
    sidebarCollapse: false,
  };

  changeSidebarVisibility = () => {
    this.setState((prevState) => ({
      sidebarCollapse: !prevState.sidebarCollapse,
    }));
  };

  changeMobileSidebarVisibility = () => {
    this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
  };

  render() {
    const { sidebarShow, sidebarCollapse } = this.state;
    const layoutClass = classNames({
      layout: true,
      "layout--collapse": sidebarCollapse,
    });

    return (
      <div className={layoutClass}>
        <ReactNotifications />
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        <Sidebar
          sidebarShow={sidebarShow}
          sidebarCollapse={sidebarCollapse}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

export default Layout;
