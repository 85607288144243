import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router";
import { useMutation } from "react-query";
import { Store } from "react-notifications-component";
import { Col, Spinner } from "reactstrap";
import axios from "axios";

import TitleWrapper from "../../../shared/components/TitleWrapper";
import StyledForm from "../../../shared/components/form/StyledForm";
import FormInput from "../../../shared/components/form/FormInput";
import SelectNew from "../../../shared/components/form/SelectNew";
import { fetchGet, boolToInt } from "../../../shared/utils";
import { Permissions } from "../../../shared/constants";
import { UserDto } from "models/UserDto";
import CheckBoxSelector from "shared/components/form/CheckBoxSelector";
import PermissionsTable from "shared/components/form/PermissionsTable";

function EditUser() {
  const { id, clientId } = useParams();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<UserDto>({ defaultValues: {}, mode: "all" });
  const [loading, setLoading] = React.useState(true);
  const [userGroups, setUserGroups] = React.useState([]);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const notificationMessageSuccess = id
    ? "User has been updated."
    : "User has been created.";
  const notificationMessageError = id
    ? "User could not be updated."
    : "New user could not be created";
  const mutation = useMutation(
    [],
    (data: UserDto) => {
      if (id) {
        return axios.post("users/update", data);
      }
      data.clientId = parseInt(clientId);
      return axios.post("users/create", data);
    },
    {
      onSuccess: (resp) => {
        Store.addNotification({
          title: "Users",
          message: notificationMessageSuccess,
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
        setShouldRedirect(true);
        fetchUser(resp.data.id);
      },
      onError: (resp) => {
        Store.addNotification({
          title: "Users",
          message: notificationMessageError,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      },
    }
  );

  //gets
  const fetchUser = async (userId) => {
      fetchGet(`users/${userId}`)
        .then((response) => response.data)
        .then((resData) => {
            //TODO null check serverside
            resData.userGroupId = resData.userGroupId || 0;
            reset(resData);
        })
        .catch((error) => {
            console.log("error getting data from server " + error);
        });
      setLoading(false);
  };
  const fetchUserGroups = async () => {
    fetchGet(`usergroups`)
      .then((response) => response.data)
      .then((resData) => {
        let options = [];
        resData.data.forEach(function (val) {
          options.push({ value: val.id, label: val.name });
        });
        setUserGroups(options);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
  };
  const emailIsUnique = (email) => {
    if (id) {
      return true;
    }
    return fetchGet("users/emailisunique/" + email)
      .then((response) => response.data)
      .then((dataRemote) => {
        if (dataRemote.result === "fail") {
          return false;
        }
        return true;
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
        return true;
      });
  };

  //posts
  const postForm = async (data: UserDto) => {
    //ensure permissions are integers and not booleans
    var permissions = Permissions();
    boolToInt(data, permissions);
    if ("isAdmin" in data) {
      data["isAdmin"] = data["isAdmin"] ? 1 : 0;
    }
    if ("isDeleted" in data) {
      data["isDeleted"] = data["isDeleted"] ? 1 : 0;
    }
    if ("mustResetPassword" in data) {
      data["mustResetPassword"] = data["mustResetPassword"] ? 1 : 0;
    }
    mutation.mutate(data);
  };

  //initialisation
  useEffect(() => {
    fetchUserGroups();
    if (id) {
      fetchUser(id);
    } else {
      setLoading(false);
    }
  }, []);

  //UI
  if (shouldRedirect) {
    let redirectLink = "";
    if (getValues("clientId") !== 0 && getValues("clientId") !== undefined) {
      redirectLink = "/pages/editclient/" + getValues("clientId");
    } else {
      redirectLink = "/pages/users";
    }
    return <Navigate to={redirectLink} />;
  }
  return (
    <TitleWrapper title="Users">
      {loading && <Spinner className="spinner" />}
      {!loading && (
        <StyledForm
          heading={id ? "Edit User" : "New User"}
          subHeading={
            (id ? "edit user details using" : "create a new user using") +
            " the form below"
          }
          submit={handleSubmit(postForm)}
          isSaving={mutation.isLoading}
          errorMessage={
            mutation.isError &&
            mutation.error instanceof Error &&
            mutation.error.message
          }
        >
          <fieldset>
            <FormInput
              fieldName="firstName"
              title="FirstName"
              type="text"
              errors={errors}
              validation={{
                minLength: {
                  value: 2,
                  message: "Must be 2 or more characters",
                },
                required: "This field is required",
              }}
              register={register}
            />
            <FormInput
              fieldName="lastName"
              title="Lastname"
              type="text"
              errors={errors}
              validation={{
                minLength: {
                  value: 2,
                  message: "Must be 2 or more characters",
                },
                required: "This field is required",
              }}
              register={register}
            />
            <FormInput
              fieldName="email"
              title="Email (username)"
              type="text"
              errors={errors}
              validation={{
                minLength: {
                  value: 8,
                  message: "Must be 8 or more characters",
                },
                validate: async (v: string) =>
                  (await emailIsUnique(v)) ||
                  "email is already in use. email address must be unique.",
                required: "This field is required",
              }}
              register={register}
            />
            <SelectNew
              fieldName="userGroupId"
              title="User Group"
              options={userGroups}
              errors={errors}
              register={register}
              defaultValue={{ value: 0, label: "None" }}
              reset={reset}
              getValues={getValues}
            />
            <CheckBoxSelector
              fieldName="isAdmin"
              title="Is Admin User"
              errors={errors}
              register={register}
            />
            {id && (
              <CheckBoxSelector
                fieldName="isDeleted"
                title="Deactivate User"
                errors={errors}
                register={register}
              />
            )}
            {id && (
              <CheckBoxSelector
                fieldName="mustResetPassword"
                title="Reset Password"
                errors={errors}
                register={register}
              />
            )}
          </fieldset>
          <Col md="6">
            {getValues("userGroupId") == 0 && (
              <PermissionsTable
                register={register}
                errors={errors}
                getValues={getValues}
                reset={reset}
                setValue={setValue}
              />
            )}
            <button
              className="btn btn-primary account__btn account__btn--small"
              type="submit"
            >
              {id && "Update User"}
              {!id && "Create New User"}
            </button>
          </Col>
        </StyledForm>
      )}
    </TitleWrapper>
  );
}

export default EditUser;
