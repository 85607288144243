import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router";
import { useMutation } from "react-query";
import { Store } from "react-notifications-component";
import { Col, Spinner } from "reactstrap";
import { ListBox } from "primereact/listbox";
import axios from "axios";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css"; // css utility

import TitleWrapper from "shared/components/TitleWrapper";
import StyledForm from "shared/components/form/StyledForm";
import {
  GeneralSettingsDto,
  GeneralSettingsModel,
} from "models/GeneralSettingsDto";
import FormInput from "shared/components/form/FormInput";
import FormInputWrapper from "shared/components/form/FormInputWrapper";
import { fetchGet, TEXTFILES, AUDIOFILES } from "../../shared/utils";
import "./Style.css";

function GeneralSettings() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<GeneralSettingsDto>({ defaultValues: {}, mode: "all" });
  const [loading, setLoading] = React.useState(true);
  const mutation = useMutation(
    [],
    (data: GeneralSettingsDto) => {
      let audioWhitelist = data.audioWhitelist.map((v) => {
        if (v.id == v.name) {
          return { id: -1, name: v.name.toUpperCase() };
        }
        return { id: parseInt(v.id), name: v.name.toUpperCase() };
      });
      let textWhitelist = data.textWhitelist.map((v) => {
        if (v.id == v.name) {
          return { id: -1, name: v.name.toUpperCase() };
        }
        return { id: parseInt(v.id), name: v.name.toUpperCase() };
      });
      let formattedData = {
        audioWhitelist: audioWhitelist,
        textWhitelist: textWhitelist,
      } as GeneralSettingsModel;
      return axios.post("generalsettings", formattedData);
    },
    {
      onSuccess: (resp) => {
        Store.addNotification({
          title: "General Settings",
          message: "Successfully updated settings.",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      },
      onError: (resp) => {
        Store.addNotification({
          title: "General Settings",
          message: "Failed to update settings.",
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      },
    }
  );

  const fetchSettings = () => {
    fetchGet(`generalsettings`)
      .then((response) => {
        let data = response.data as GeneralSettingsDto;
        //if nothing in database, use constants instead
        if (data.audioWhitelist.length != 0) {
          data.audioWhitelist = data.audioWhitelist.map((v) => {
            return { id: v.id.toString(), name: v.name.toLowerCase() };
          });
        } else {
          data.audioWhitelist = AUDIOFILES.map((v, i) => {
            return { id: (i + 1).toString(), name: v.toLowerCase() };
          });
        }
        if (data.textWhitelist.length != 0) {
          data.textWhitelist = data.textWhitelist.map((v) => {
            return { id: v.id.toString(), name: v.name.toLowerCase() };
          });
        } else {
          data.textWhitelist = TEXTFILES.map((v, i) => {
            return { id: (i + 1).toString(), name: v.toLowerCase() };
          });
        }
        reset(data);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchSettings();
    setLoading(false);
  }, []);

  const postForm = async (data: GeneralSettingsDto) => {
    mutation.mutate(data);
  };

  const addAudioFileExtension = (event) => {
    var value = getValues("audioInput");
    if (!value) {
      return;
    }
    var existing = getValues("audioWhitelist") || [];
    setValue("audioWhitelist", [...existing, { name: value, id: value }], {
      shouldDirty: true,
    });
    setValue("audioInput", "", {
      shouldDirty: true,
    });
  };

  const addTextFileExtension = (event) => {
    var value = getValues("textInput");
    if (!value) {
      return;
    }
    var existing = getValues("textWhitelist") || [];
    setValue("textWhitelist", [...existing, { name: value, id: value }], {
      shouldDirty: true,
    });
    setValue("textInput", "", {
      shouldDirty: true,
    });
  };

  const removeAudioFileExtension = (event) => {
    var field = event.target;
    var value = field.value;
    var existing = (getValues("audioWhitelist") || []).filter(
      (v) => v.id != value.id
    );
    setValue("audioWhitelist", [...existing], {
      shouldDirty: true,
    });
  };

  const removeTextFileExtension = (event) => {
    var field = event.target;
    var value = field.value;
    var existing = (getValues("textWhitelist") || []).filter(
      (v) => v.id != value.id
    );
    setValue("textWhitelist", [...existing], {
      shouldDirty: true,
    });
  };

  const addAudioFileOnEnter = (event) => {
    if (event.keyCode == 13) {
      event.preventDefault();
      addAudioFileExtension(event);
    }
  };
  const addTextFileOnEnter = (event) => {
    if (event.keyCode == 13) {
      event.preventDefault();
      addTextFileExtension(event);
    }
  };

  //UI
  const logoPath = `${process.env.PUBLIC_URL}/img/remove.png`;

  const fileTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={logoPath}
          className={`flag flag-${option.id.toLowerCase()}`}
          style={{ width: "0.5rem", marginRight: ".5rem" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <TitleWrapper title="General Settings">
      {loading && <Spinner className="spinner" />}
      {!loading && (
        <StyledForm
          heading={""}
          subHeading={""}
          submit={handleSubmit(postForm)}
          isSaving={mutation.isLoading}
          errorMessage={
            mutation.isError &&
            mutation.error instanceof Error &&
            mutation.error.message
          }
        >
          <fieldset>
            <FormInputWrapper
              title="Accepted Audio/Video File Extensions"
              error={errors["audioWhitelist"]}
            >
              <input
                type={"text"}
                placeholder={"Enter a file extension"}
                onKeyDown={addAudioFileOnEnter}
                {...register("audioInput")}
              />
              <button
                type="button"
                style={{ width: "10%" }}
                onClick={addAudioFileExtension}
              >
                Add
              </button>
            </FormInputWrapper>
            <FormInputWrapper title="" error={errors["audioWhitelist"]}>
              <Controller
                name="audioWhitelist"
                control={control}
                render={({ field }) => (
                  <ListBox
                    filter
                    value={field.value}
                    optionLabel="name"
                    name="value"
                    options={getValues("audioWhitelist")}
                    onChange={removeAudioFileExtension}
                    itemTemplate={fileTemplate}
                    style={{ width: "15rem" }}
                    listStyle={{ maxHeight: "200px" }}
                  />
                )}
              />
            </FormInputWrapper>
            <FormInputWrapper
              title="Accepted Text File Extensions"
              error={errors["textWhitelist"]}
            >
              <input
                type={"text"}
                placeholder={"Enter a file extension"}
                onKeyDown={addTextFileOnEnter}
                {...register("textInput")}
              />
              <button
                type="button"
                style={{ width: "10%" }}
                onClick={addTextFileExtension}
              >
                Add
              </button>
            </FormInputWrapper>

            <FormInputWrapper title="" error={errors["textWhitelist"]}>
              <Controller
                name="textWhitelist"
                control={control}
                render={({ field }) => (
                  <ListBox
                    filter
                    value={field.value}
                    optionLabel="name"
                    name="value"
                    options={getValues("textWhitelist")}
                    onChange={removeTextFileExtension}
                    itemTemplate={fileTemplate}
                    style={{ width: "15rem" }}
                    listStyle={{ maxHeight: "200px" }}
                  />
                )}
              />
            </FormInputWrapper>
            <button
              className="btn btn-primary account__btn account__btn--small"
              type="submit"
            >
              Update Settings
            </button>
          </fieldset>
        </StyledForm>
      )}
    </TitleWrapper>
  );
}

export default GeneralSettings;
