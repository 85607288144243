import React from 'react';
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {Path} from "react-hook-form";
import { Col } from 'reactstrap';


type Props<T> = {
  title: string;
  fieldName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  validation?: any; 
  disabled?: boolean;
  onChange?: any;
}

function ConfirmationBox<T>(props: Props<T>) {

  const {title, fieldName, register, validation, errors, disabled, onChange} = props;
  
  //the errors object comes as an object with the sub form objects, which need to be flattened so the correct fieldName is used to access it later
  let errorsTransposed={...errors};
  for (const subForm of ['licence','transcript','police','registration','skipTrace','fieldCall','thirdPartyAddress','additionalAddress']) {
    if(errors[subForm])
    {
      for (const [key, value] of Object.entries(errors[subForm])) {
        errorsTransposed[subForm+'.'+key]=value;
      }
      delete errorsTransposed[subForm];
    }
  }

  return (
    <Col md="6">
        <div className='form__form-group'>
            <span className="form__form-group-label"><input {...register(fieldName, {...validation})} onChange={onChange} type="checkbox" disabled={disabled}/></span>
            <div className="form__form-group-field">
                <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
                    {title}
                    {errorsTransposed[fieldName as string] && <span className="form__form-group-error">{errorsTransposed[fieldName as string].message}</span>}
                </div>
            </div>
        </div>
    </Col>    
  );
}

export default ConfirmationBox;


