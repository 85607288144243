import React, { PureComponent } from "react";
import { Spinner } from "reactstrap";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Redirect } from "react-router-dom";
import { fetchPost } from "../../../shared/utils";
import {Navigate} from "react-router";

function ValidationMessage(props) {
  if (!props.valid) {
    return (
      <div className="alert alert-danger">
        <p>{props.message}</p>
      </div>
    );
  }
  return null;
}

class ResetPasswordRequestForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      resetEmailSent: false,
      resetEmailSentMessage: "",
      username: "",
      usernameValid: true,
      usernameErrorMsg: "",
      formValid: false,
      isLoading: false,
    };
  }

  validateForm = () => {
    const { usernameValid } = this.state;

    let isFormValid = usernameValid;

    this.setState({ formValid: isFormValid });

    return isFormValid;
  };

  updateUsername = (username) => {
    this.setState({ username });
  };

  validateUsername = () => {
    const { username } = this.state;
    let usernameValid = true;
    let errorMsg = this.state.usernameErrorMsg;

    if (!username || username.length < 5) {
      usernameValid = false;
      errorMsg = "please enter a valid email address";
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) {
      usernameValid = false;
      errorMsg = "please enter a valid email address";
    }

    this.setState(
      { usernameValid: usernameValid, usernameErrorMsg: errorMsg },
      this.validateForm
    );
  };

  handleResetPassword = (e) => {
    e.preventDefault();

    if (this.state.isLoading) {
      return null;
    }

    this.setState({
      isLoading: true,
    });

    if (!this.validateForm()) {
      this.setState({
        isLoading: false,
      });
      return null;
    }
    fetchPost("users/resetpasswordrequest", {
      email: this.state.username,
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          resetEmailSent: true,
          resetEmailSentMessage:
            "If the email is registered with the system you will be sent instructions on resetting your password.",
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          resetEmailSent: false,
          resetEmailSentMessage:
            "If the email is registered with the system you will be sent instructions on resetting your password.",
          isLoading: false,
        });
      });
  };

  formResult() {
    if (this.state.resetEmailSent) {
      return (
        <div className="alert alert-info">
          <p>{this.state.resetEmailSentMessage}</p>
        </div>
      );
    }
  }

  render() {
    // const { showPassword } = this.state;

    if (this.state.shouldRedirect) {
      return <Navigate to="/pages/claimlist" />;
    }

    return (
      <form className="form">
        <p>Please enter your email address to reset your password</p>
        <div className="form__form-group">
          <span className="form__form-group-label"></span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input
              name="username"
              type="text"
              placeholder="email"
              value={this.state.username}
              onChange={(e) => this.updateUsername(e.target.value)}
            />
          </div>
          <ValidationMessage
            valid={this.state.usernameValid}
            message={this.state.usernameErrorMsg}
          />
        </div>
        <div className="form__form-group">{this.formResult()}</div>

        {!this.state.resetEmailSent ? (
          <button
            className="btn btn-primary account__btn account__btn--small"
            onClick={(e) => {
              this.handleResetPassword(e);
            }}
          >
            {this.state.isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#FFFFFF" }}
              />
            ) : (
              "Send password reset email"
            )}
          </button>
        ) : null}

        <a href="/log_in">Back to the login page</a>
      </form>
    );
  }
}

export default ResetPasswordRequestForm;
