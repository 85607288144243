import React, { PureComponent } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import jwt_decode from "jwt-decode";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      UserName: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("jwt") !== null) {
      var decoded = jwt_decode(localStorage.getItem("jwt_access"));
      this.setState({
        UserName: decoded.given_name + " " + decoded.family_name,
      });
    }
  }

  handleLogout = (e) => {
    localStorage.clear("jwt_access");
    localStorage.clear("jwt_refresh");
    window.location.href = '/log_in';
  };

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{this.state.UserName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && (
          <button
            type="button"
            className="topbar__back"
            onClick={this.toggle}
          />
        )}

        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title="My Details"
              icon="user"
              path="/pages/userdetails"
            />
            <a className="topbar__link" >
              <span className={`topbar__link-icon lnr lnr-exit`} />
              <p className="topbar__link-title" onClick={this.handleLogout}>
                Log Out
              </p>
            </a>
          </div>
        </Collapse>
      </div>
    );
  }
}
