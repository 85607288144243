import React, { PureComponent } from "react";
import { Spinner } from "reactstrap";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
// import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Redirect } from "react-router-dom";
// import CheckBox from "../../../shared/components/form/CheckBox";
import {
  fetchGet,
  fetchPost,
  loginSuccess,
  passwordValidation,
} from "../../../shared/utils";
import { Navigate } from "react-router";

function ValidationMessage(props) {
  if (!props.valid) {
    return (
      <div className="alert alert-danger">
        <p>{props.message}</p>
      </div>
    );
  }
  return null;
}

class EmailConfirmationForm extends PureComponent {
  constructor() {
    super();

    let confirmationEmailUrl = window.location.href.split("/");
    let confirmationCode = confirmationEmailUrl.pop();

    this.state = {
      showPassword: false,
      loginFailed: false,
      loginMessage: "",
      shouldRedirect: false,
      password: "",
      passwordValid: true,
      passwordErrorMsg: "",
      confirmPassword: "",
      confirmPasswordValid: true,
      confirmPasswordErrorMsg: "",
      formValid: false,
      isLoading: false,
      emailValidationCode: confirmationCode,
      isValidConfirmationCode: true,
    };
  }

  validateForm = () => {
    const { confirmPasswordValid, passwordValid } = this.state;
    this.setState({
      formValid: confirmPasswordValid && passwordValid,
    });
  };

  updateConfirmPassword = (confirmPassword) => {
    this.setState({ confirmPassword }, this.validateConfirmPassword);
  };

  validateConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    let confirmPasswordValid = true;
    let errorMsg = this.state.confirmPasswordErrorMsg;

    if (password !== confirmPassword) {
      confirmPasswordValid = false;
      errorMsg = "passwords do not match";
    }

    this.setState(
      {
        confirmPasswordValid: confirmPasswordValid,
        confirmPasswordErrorMsg: errorMsg,
      },
      this.validateForm
    );
  };

  updatePassword = (password) => {
    this.setState({ password }, this.validatePassword);
  };

  validatePassword = () => {
    const { password } = this.state;
    let passwordValid = true;
    let errorMsg = this.state.passwordErrorMsg;

    const error = passwordValidation(password);
    if (error) {
      passwordValid = false;
      errorMsg = error;
    }

    this.setState(
      { passwordValid: passwordValid, passwordErrorMsg: errorMsg },
      this.validateForm
    );
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.isLoading) {
      return null;
    }

    this.setState({
      isLoading: true,
    });

    this.validateConfirmPassword();
    this.validatePassword();

    if (!this.state.formValid) {
      this.setState({
        isLoading: false,
      });
      return null;
    }

    fetchPost("users/confirmemail", {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      confirmationCode: this.state.emailValidationCode,
    })
      .then((response) => response.data)
      .then((data) => {
        if (!data.token) {
          this.setState({
            loginFailed: true,
            loginMessage: "Account confirmation failed",
            isLoading: false,
          });
        } else {
          loginSuccess(data);
          this.setState({ shouldRedirect: true });
        }
      })
      .catch((error) => {
        this.setState({
          loginFailed: true,
          loginMessage: "Account confirmation failed",
          isLoading: false,
        });
      });
  };

  loginResult() {
    if (this.state.loginFailed) {
      return (
        <div className="alert alert-danger">
          <p>{this.state.loginMessage}</p>
        </div>
      );
    }
  }

  componentDidMount = () => {
    fetchGet("users/validatetoken/" + this.state.emailValidationCode)
      .then((response) => response.data)
      .then((dataRemote) => {
        if (dataRemote.result === "fail") {
          this.setState({
            isValidConfirmationCode: false,
          });
        }
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
        this.setState({
          isValidConfirmationCode: false,
        });
      });
  };

  render() {
    const { showPassword } = this.state;

    if (this.state.shouldRedirect) {
      return <Navigate to="/pages/claimlist" />;
    }

    return (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={this.state.password}
              onChange={(e) => this.updatePassword(e.target.value)}
            />
            <button
              className={`form__form-group-button${
                showPassword ? " active" : ""
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/">Forgot a password?</a>
          </div>
          <ValidationMessage
            valid={this.state.passwordValid}
            message={this.state.passwordErrorMsg}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              placeholder="confirm password"
              value={this.state.confirmPassword}
              onChange={(e) => this.updateConfirmPassword(e.target.value)}
            />
            <button
              className={`form__form-group-button${
                showPassword ? " active" : ""
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/">Forgot a password?</a>
          </div>
          <ValidationMessage
            valid={this.state.confirmPasswordValid}
            message={this.state.confirmPasswordErrorMsg}
          />
        </div>

        <div className="form__form-group">{this.loginResult()}</div>

        {this.state.isValidConfirmationCode ? (
          <button
            className="btn btn-primary account__btn account__btn--small"
            onClick={(e) => {
              this.handleFormSubmit(e);
            }}
          >
            {this.state.isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#FFFFFF" }}
              />
            ) : (
              "Sign In"
            )}
          </button>
        ) : (
          <div className="alert alert-danger">
            <p>Email confirmation is no longer valid or has expired.</p>
          </div>
        )}
      </form>
    );
  }
}

export default EmailConfirmationForm;
