import React, { useState } from 'react';
import {Path} from "react-hook-form";
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import FormInputWrapper from "./FormInputWrapper";

type Props<T> = {
  title: string;
  fieldName: Path<T>;
  register: UseFormRegister<T>;
  options: string[];
  errors: FieldErrors<T>;
  validation?: any;
  disabled?: boolean;
}

function FormInputSelect<T>(props: Props<T>) {

  const {title, fieldName, options, register, validation, errors, disabled} = props;

  //const [selected, setSelected] = useState('');

  //the errors object comes as an object with the sub form objects, which need to be flattened so the correct fieldName is used to access it later
  let errorsTransposed={...errors};
  for (const subForm of ['licence','transcript','police','registration','skipTrace','fieldCall','thirdPartyAddress','additionalAddress']) {
    if(errors[subForm])
    {
      for (const [key, value] of Object.entries(errors[subForm])) {
        errorsTransposed[subForm+'.'+key]=value;
      }
      delete errorsTransposed[subForm];
    }
  }

  const optionsWithDefault = [title, ...options].map(option => {
    if (option==title) {
      return (<option key={fieldName+option} value='' disabled={true} hidden >{option}</option>);
    }
    else{
      return (<option key={fieldName+option} value={option}>{option}</option>);
    }
  })

  return (
    <FormInputWrapper title={title} error={errorsTransposed[fieldName as string]}>
      <select className="react-select" defaultValue='' {...register(fieldName, {...validation})} disabled={disabled}>
        {optionsWithDefault}
      </select>
    </FormInputWrapper>
  );
}

export default FormInputSelect;