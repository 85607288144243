import React, { PureComponent } from "react";
import { Spinner } from "reactstrap";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Redirect } from "react-router-dom";
import CheckBox from "../../../shared/components/form/CheckBox";
import { fetchPost, loginSuccess, setDelay } from "../../../shared/utils";
import { Navigate } from "react-router";

function ValidationMessage(props) {
  if (!props.valid) {
    return (
      <div className="alert alert-danger">
        <p>{props.message}</p>
      </div>
    );
  }
  return null;
}

class LogInForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      loginFailed: false,
      loginMessage: "",
      shouldRedirect: false,
      username: "",
      usernameValid: true,
      usernameErrorMsg: "",
      password: "",
      passwordValid: true,
      passwordErrorMsg: "",
      formValid: false,
      isLoading: false,
      resetToken: "",
      lockout: false,
    };
  }

  validateForm = () => {
    const { usernameValid, passwordValid } = this.state;
    this.setState({
      formValid: usernameValid && passwordValid,
    });
  };

  updateUsername = (username) => {
    this.setState({ username }, this.validateUsername);
  };

  validateUsername = () => {
    const { username } = this.state;
    let usernameValid = true;
    let errorMsg = this.state.usernameErrorMsg;

    if (!username || username.length === 0) {
      usernameValid = false;
      errorMsg = "please enter a username";
    }

    this.setState(
      { usernameValid: usernameValid, usernameErrorMsg: errorMsg },
      this.validateForm
    );
  };

  updatePassword = (password) => {
    this.setState({ password }, this.validatePassword);
  };

  validatePassword = () => {
    const { password } = this.state;
    let passwordValid = true;
    let errorMsg = this.state.passwordErrorMsg;

    if (!password || password.length === 0) {
      passwordValid = false;
      errorMsg = "please enter a password";
    }

    this.setState(
      { passwordValid: passwordValid, passwordErrorMsg: errorMsg },
      this.validateForm
    );
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleLogin = (e) => {
    e.preventDefault();

    if (this.state.isLoading) {
      return null;
    }

    this.setState({
      isLoading: true,
    });

    this.validateUsername();
    this.validatePassword();

    if (!this.state.formValid) {
      this.setState({
        isLoading: false,
      });
      return null;
    }

    fetchPost("users/authenticate", {
      email: this.state.username,
      password: this.state.password,
    })
      .then((response) => response.data)
      .then((data) => {
        if (!data.token) {
          if (data.resettoken) {
            this.setState({ resetToken: data.resettoken });
          } else {
            this.setState({
              loginFailed: true,
              loginMessage: data.message || "Username or Password is incorrect",
              isLoading: false,
              lockout: true,
            });
            setDelay(4000).then(() => this.setState({ lockout: false }));
          }
        } else {
          loginSuccess(data);
          this.setState({ shouldRedirect: true });
        }
      })
      .catch((error) => {
        this.setState({
          loginFailed: true,
          loginMessage:
            error.response.data.message || "Username or Password is incorrect",
          isLoading: false,
          lockout: true,
        });
        setDelay(4000).then(() => this.setState({ lockout: false }));
      });
  };

  loginResult() {
    if (this.state.loginFailed) {
      return (
        <div className="alert alert-danger">
          <p>{this.state.loginMessage}</p>
        </div>
      );
    }
  }

  render() {
    const { showPassword } = this.state;

    if (this.state.shouldRedirect) {
      return <Navigate to="/pages/newclaim" />;
    }

    if (this.state.resetToken) {
      return <Navigate to={"/resetpassword/" + this.state.resetToken} />;
    }

    return (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input
              name="username"
              type="text"
              placeholder="username"
              value={this.state.username}
              onChange={(e) => this.updateUsername(e.target.value)}
            />
          </div>
          <ValidationMessage
            valid={this.state.usernameValid}
            message={this.state.usernameErrorMsg}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={this.state.password}
              onChange={(e) => this.updatePassword(e.target.value)}
            />
            <button
              className={`form__form-group-button${
                showPassword ? " active" : ""
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/resetpasswordrequest">Forgot your password?</a>
          </div>
          <ValidationMessage
            valid={this.state.passwordValid}
            message={this.state.passwordErrorMsg}
          />
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <CheckBox
              name="remember_me"
              label="Remember me"
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="form__form-group">{this.loginResult()}</div>
        <button
          className="btn btn-primary account__btn account__btn--small"
          disabled={this.state.lockout}
          onClick={(e) => {
            this.handleLogin(e);
          }}
        >
          {this.state.isLoading ? (
            <Spinner
              style={{ width: "1rem", height: "1rem", color: "#FFFFFF" }}
            />
          ) : (
            "Sign In"
          )}
        </button>
      </form>
    );
  }
}

export default LogInForm;
