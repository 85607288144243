import React, {PropsWithChildren} from 'react';
import {Col, Table} from "reactstrap";
import { FieldErrors, UseFormRegister, UseFormGetValues, UseFormReset, UseFormSetValue, Path, PathValue} from 'react-hook-form';

import CheckBoxNew from './CheckBoxNew';
import {StateOptions, SearchTypes, Permissions, DisabledPermissions} from '../../constants';

type Props<T> = {
  register: UseFormRegister<T>;
  getValues: UseFormGetValues<T>;
  setValue: UseFormSetValue<T>;
  errors: FieldErrors<T>;
  reset?: UseFormReset<T>;
}

function PermissionsTable<T>(props: Props<T>) {

  const {register, getValues, reset, setValue, errors} = props;

  const tickAllInRow = (event) => {
    let field = event.target;
    let row = field.id.split("_")[0];
    if(!row) {
      return;
    }
    let values = getValues();
    let value = values[field.name] === 0 ? 1 : 0;
    let keys = Object.keys(values);
    keys.forEach(key=>{
      if(key.includes(row)) {
        setValue(key as Path<T>, value as PathValue<T, Path<T>>);
        //values[key] = value;
      }
    });
    //reset(values);
  }

  const tickOne = (e) => {
    const checkboxState=e.target.checked;
    const checkboxName=e.target.name;
    setValue(checkboxName, checkboxState);
  }

  //UI
  const tableHeaders = () => {
    var headers = [<th>Search</th>]
      .concat(StateOptions.map(v=><th>{v}</th>));
    return headers;
  }
  
  const tableRows = () => {
    var rows = [];
    for(var i = 0; i <= Object.keys(SearchTypes).length - 1; i ++) {
      let rowHeader = Object.keys(SearchTypes)[i];
      let rowPermissions = Permissions(rowHeader);
      let onchange;
      if(rowHeader === 'transcript') {
        onchange = tickAllInRow;
      }
      else{
        onchange = tickOne;
      }
      rows.push(
        <tr>
          <td>
            {SearchTypes[rowHeader]}
          </td>
          {checkBoxes(rowPermissions, onchange)}
        </tr>
      );
    }      
    return rows;
  }
  
  const checkBoxes = (rowPermissions, onchange) => {
    var checkBoxes = [];
    rowPermissions.forEach(permission => {
      checkBoxes.push(
        <td>
          <CheckBoxNew
            id={permission.id}
            fieldName={permission.name} 
            title=""
            errors={errors} 
            register={register}
            disabled={DisabledPermissions.includes(permission.name) ? true : false}
            onchange={onchange}
          />
        </td>
      );
    });
    return checkBoxes;
  }

  return (
    <Table>
      <thead>
        <tr>
          {tableHeaders()}
        </tr>
      </thead>
      <tbody>
        {tableRows()}
      </tbody>
    </Table>
  );
}

export default PermissionsTable;