import React from 'react';
import PropTypes from 'prop-types';
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {Path} from "react-hook-form";
import FormInputWrapper from "./FormInputWrapper";


type Props<T> = {
  title: string;
  fieldName: Path<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  validation?: any; 
  disabled?: boolean;
}

function CheckBoxSelector<T>(props: Props<T>) {

  const {title, fieldName, register, validation, errors, disabled} = props;

  //the errors object comes as an object with the sub form objects, which need to be flattened so the correct fieldName is used to access it later
  let errorsTransposed={...errors};
  for (const subForm of ['licence','transcript','police','registration','skipTrace','fieldCall','thirdPartyAddress','additionalAddress']) {
    if(errors[subForm])
    {
      for (const [key, value] of Object.entries(errors[subForm])) {
        errorsTransposed[subForm+'.'+key]=value;
      }
      delete errorsTransposed[subForm];
    }
  }

  return (
    <FormInputWrapper title={title} error={errorsTransposed[fieldName as string]}>
        <label className="checkbox-btn__label">
          <input
            {...register(fieldName, {...validation})}
            type="checkbox" disabled={disabled} />
        </label>
    </FormInputWrapper>
  );
}

export default CheckBoxSelector;