import React from 'react';
import FormInput from "../../../shared/components/form/FormInput";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {NewClaimDto} from "../../../models/Claims/NewClaimDto";
import {UseFormRegister, UseFormWatch} from "react-hook-form/dist/types/form";
import FormInputSelect from "../../../shared/components/form/FormInputSelect";
import RadioOptionSelector from "../../../shared/components/form/RadioOptionSelector";
import ConfirmationBox from 'shared/components/form/ConfirmationBox';
import {dateBeforeToday, checkPdfFiletype, checkPdfFileSize} from "../../../shared/utils";
type Props = {
  errors: FieldErrors<NewClaimDto>;
  statePermissions: Array<string>;
  register: UseFormRegister<NewClaimDto>;
  watch: UseFormWatch<NewClaimDto>;
  disabledFields: Array<string>;
}

const ClaimTypeOptions = ['Motor Vehicle Claim', 'Property Damage Claim'];

function NewPolice(props: Props) {
  const { errors, statePermissions, register, watch, disabledFields } = props;
  const state = watch("state");
  return (
    <>
      <FormInput 
        fieldName="insuredName" 
        title="Insured name" 
        type='text' 
        errors={errors} 
        validation={{
          required: "This field is required"
        }} 
        register={register} 
        disabled={disabledFields.includes('insuredName')}
      />
      <FormInput 
        fieldName="insuredRegistration" 
        title="Insured Registration" 
        type="text" 
        validation={{
          //minLength: {value: 4, message: "Must be 4 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('insuredRegistration')}
      />
      <FormInput 
        fieldName="police.insuredAddress" 
        title="Insured Address" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"},          
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('police.insuredAddress')}
      />
      <FormInputSelect 
        fieldName="state" 
        options={statePermissions} 
        title="State" 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('state')}
      />
      {state === 'ACT' && 
        <FormInput 
          fieldName="dateOfBirth" 
          title="Insured DOB" 
          type="date" 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required"
          }}
          disabled={disabledFields.includes('dateOfBirth')}
        />
      }
      <FormInput 
        fieldName="incidentDate" 
        title="Accident Date" 
        type="date" 
        errors={errors} 
        register={register} 
        validation={{
          validate: {dateBeforeToday: (v:Date) => dateBeforeToday(v) || "The Date of Loss must be in the past"},
          required: "This field is required"
        }}
        disabled={disabledFields.includes('incidentDate')}
      />
      <FormInput 
        fieldName="incidentLocation" 
        title="Accident Location" 
        type="text" 
        validation={{
          minLength: {value: 2, message: "Must be 2 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentLocation')}
      />
      <FormInput 
        fieldName="incidentDetails" 
        title="Accident Description" 
        type="text" 
        validation={{
          minLength: {value: 10, message: "Must be 10 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register} 
        disabled={disabledFields.includes('incidentDetails')}
      />
      <RadioOptionSelector 
        fieldName="police.claimType" 
        title="Claim Type" 
        options={ClaimTypeOptions} 
        register={register} 
        errors={errors} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('police.claimType')}
      />
      <FormInput 
        fieldName="police.policeReportNumber" 
        title="Police Report Number" 
        type="text" 
        validation={{
          minLength: {value: 5, message: "Must be 5 or more characters"},
          required: "This field is required"
        }} 
        errors={errors} 
        register={register}
        disabled={disabledFields.includes('police.policeReportNumber')} 
      />
      <FormInput 
        fieldName="police.detailsAttendingPolice" 
        title="Police Attending Details" 
        type="text" 
        validation={{
          required: "This field is required",
          minLength: {value: 5, message: "Must be 5 or more characters"}
        }} 
        errors={errors} 
        register={register}
        disabled={disabledFields.includes('police.detailsAttendingPolice')}
      />
      {(state === 'VIC' || state === 'WA' || state === 'SA') && 
        <FormInput 
          fieldName="police.authorityLetter" 
          title="Authority Letter" 
          type="file" 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required",
            validate: {
              checkPdfFiletype: (v:File) => checkPdfFiletype(v) || "You must supply a PDF file",
              checkPdfFileSize: (v:File) => checkPdfFileSize(v) || "The file must be less than 6MB"
            }
          }}
          disabled={disabledFields.includes('police.authorityLetter')}
        />
      }
      {state === 'SA' && 
        <ConfirmationBox 
          fieldName="enquiryConfirmation" 
          title="I confirm that I have spoken with the insured and confirmed their identity using personal details." 
          errors={errors} 
          register={register} 
          validation={{
            required: "This field is required"
          }}
          disabled={disabledFields.includes('enquiryConfirmation')}
        />
      }
      <ConfirmationBox 
        fieldName="policeConfirmation" 
        title="As per the State and Territory regulatory requirement, I confirm that CCSG Legal Pty Ltd are instructed to obtain a police report and I agree that the information obtained will only be used for the purpose of investigating an insurance claim, commencing or defending legal proceedings and not for any other purpose." 
        errors={errors} 
        register={register} 
        validation={{
          required: "This field is required"
        }}
        disabled={disabledFields.includes('policeConfirmation')}
      />
    </>
  );
}

export default NewPolice;
