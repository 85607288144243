import React, { PureComponent } from "react";
import { Spinner } from "reactstrap";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
// import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
// import { Redirect } from "react-router-dom";
import { fetchGet, fetchPost, passwordValidation } from "../../../shared/utils";

function ValidationMessage(props) {
  if (!props.valid) {
    return (
      <div className="alert alert-danger">
        <p>{props.message}</p>
      </div>
    );
  }
  return null;
}

class ResetPasswordForm extends PureComponent {
  constructor() {
    super();

    let confirmationUrl = window.location.href.split("/");
    let confirmationCode = confirmationUrl.pop();

    this.state = {
      showPassword: false,
      resetResult: false,
      resetMessage: "",
      password: "",
      passwordValid: true,
      passwordErrorMsg: "",
      confirmPassword: "",
      confirmPasswordValid: true,
      confirmPasswordErrorMsg: "",
      emailValidationCode: confirmationCode,
      isValidConfirmationCode: true,
      formValid: false,
      isLoading: false,
    };
  }

  componentDidMount = () => {
    fetchGet("users/validateresettoken/" + this.state.emailValidationCode)
      .then((response) => response.data)
      .then((dataRemote) => {
        if (dataRemote.message) {
          this.setState({ resetMessage: dataRemote.message });
        }
        if (dataRemote.result === "fail") {
          this.setState({
            isValidConfirmationCode: false,
          });
        }
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
        this.setState({
          isValidConfirmationCode: false,
        });
      });
  };

  validateForm = () => {
    const { confirmPasswordValid, passwordValid } = this.state;

    let isFormValid = confirmPasswordValid && passwordValid;

    this.setState({ formValid: isFormValid });

    return isFormValid;
  };

  updatePassword = (password) => {
    this.setState({ password }, this.validatePassword);
  };

  updateConfirmPassword = (confirmPassword) => {
    this.setState({ confirmPassword }, this.validateConfirmPassword);
  };

  validateConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    let confirmPasswordValid = true;
    let errorMsg = this.state.confirmPasswordErrorMsg;

    if (password !== confirmPassword) {
      confirmPasswordValid = false;
      errorMsg = "passwords do not match";
    }

    this.setState(
      {
        confirmPasswordValid: confirmPasswordValid,
        confirmPasswordErrorMsg: errorMsg,
      },
      this.validateForm
    );
  };

  validatePassword = () => {
    const { password } = this.state;
    let passwordValid = true;
    let errorMsg = this.state.passwordErrorMsg;

    const error = passwordValidation(password);
    if (error) {
      passwordValid = false;
      errorMsg = error;
    }

    this.setState(
      { passwordValid: passwordValid, passwordErrorMsg: errorMsg },
      this.validateForm
    );
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.isLoading) {
      return null;
    }

    this.setState({
      isLoading: true,
    });

    if (!this.validateForm()) {
      this.setState({
        isLoading: false,
      });
      return null;
    }
    fetchPost("users/resetpassword", {
      confirmationCode: this.state.emailValidationCode,
      password: this.state.password,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.result !== "success") {
          this.setState({
            resetResult: false,
            resetMessage: "Password could not be reset",
            isLoading: false,
          });
        } else {
          this.setState({
            resetResult: true,
            resetMessage: "Password has been reset",
            isLoading: false,
            isValidConfirmationCode: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          resetResult: false,
          resetMessage: "Password could not be reset",
          isLoading: false,
        });
      });
  };

  resetResult() {
    if (this.state.resetResult && this.state.resetMessage.length > 0) {
      return (
        <div className="alert alert-success">
          <p>
            {this.state.resetMessage}.{" "}
            <a href="/log_in">Return back to the login page</a>
          </p>
        </div>
      );
    } else if (this.state.resetMessage.length > 0) {
      return (
        <div className="alert alert-danger">
          <p>{this.state.resetMessage}</p>
        </div>
      );
    }
  }

  render() {
    const { showPassword } = this.state;

    return (
      <form className="form">
        {this.state.isValidConfirmationCode ? (
          <div className="form__form-group">
            <br />
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.updatePassword(e.target.value)}
              />
              <button
                className={`form__form-group-button${
                  showPassword ? " active" : ""
                }`}
                onClick={(e) => this.showPassword(e)}
                type="button"
              >
                <EyeIcon />
              </button>
            </div>
            <ValidationMessage
              valid={this.state.passwordValid}
              message={this.state.passwordErrorMsg}
            />
          </div>
        ) : null}

        {this.state.isValidConfirmationCode ? (
          <div className="form__form-group">
            <span className="form__form-group-label">Confirm Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <input
                name="confirmPassword"
                type={showPassword ? "text" : "password"}
                placeholder="confirm password"
                value={this.state.confirmPassword}
                onChange={(e) => this.updateConfirmPassword(e.target.value)}
              />
              <button
                className={`form__form-group-button${
                  showPassword ? " active" : ""
                }`}
                onClick={(e) => this.showPassword(e)}
                type="button"
              >
                <EyeIcon />
              </button>
            </div>
            <ValidationMessage
              valid={this.state.confirmPasswordValid}
              message={this.state.confirmPasswordErrorMsg}
            />
          </div>
        ) : null}

        <div className="form__form-group">{this.resetResult()}</div>

        {this.state.isValidConfirmationCode ? (
          <button
            className="btn btn-primary account__btn account__btn--small"
            onClick={(e) => {
              this.handleFormSubmit(e);
            }}
          >
            {this.state.isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#FFFFFF" }}
              />
            ) : (
              "Reset Password"
            )}
          </button>
        ) : null}

        {!this.state.isValidConfirmationCode && !this.state.resetResult ? (
          <div className="alert alert-danger">
            <p>Password Reset Request is no longer valid or has expired.</p>
          </div>
        ) : null}
      </form>
    );
  }
}

export default ResetPasswordForm;
