import React from 'react';
import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {Path} from "react-hook-form";
import CheckIcon from 'mdi-react/CheckIcon';

type Props<T> = {
  id: string;
  title: string;
  fieldName: Path<T>;
  register: UseFormRegister<T>;
  validation?: any; 
  errors: FieldErrors<T>;
  disabled?: boolean;
  onchange?: Function;
}

function CheckBoxNew<T>(props: Props<T>) {

  const {id, title, fieldName, register, validation, errors, disabled, onchange} = props;
  //the errors object comes as an object with the sub form objects, which need to be flattened so the correct fieldName is used to access it later
  let errorsTransposed={...errors};
  for (const subForm of ['licence','transcript','police','registration','skipTrace','fieldCall','thirdPartyAddress','additionalAddress']) {
    if(errors[subForm])
    {
      for (const [key, value] of Object.entries(errors[subForm])) {
        errorsTransposed[subForm+'.'+key]=value;
      }
      delete errorsTransposed[subForm];
    }
  }

  return (
    <label className={(disabled ? "disabled " : "") + "checkbox-btn"} key={fieldName+"_label"}>
        <input
          {...register(fieldName, {onchange: e => {onchange(e)}, ...validation})}
          id={id}
          className="checkbox-btn__checkbox"
          type="checkbox"  
          disabled={disabled}
          onChange={(event)=>{onchange(event)}}
        />
        <span className="checkbox-btn__checkbox-custom">
        <CheckIcon />
        </span>
        <span className="checkbox-btn__label">
          {title}
        </span>
    </label>
  );
}

export default CheckBoxNew;