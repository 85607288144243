import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router";
import { useMutation } from "react-query";
import { Store } from "react-notifications-component";
import { Card, CardBody, Col, Spinner, Table } from "reactstrap";
import { Link } from "react-router-dom";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";
import axios from "axios";

import TitleWrapper from "../../../shared/components/TitleWrapper";
import StyledForm from "../../../shared/components/form/StyledForm";
import FormInput from "../../../shared/components/form/FormInput";
import { fetchGet, boolToInt } from "../../../shared/utils";
import { ClientDto } from "models/ClientDto";
import { Permissions } from "../../../shared/constants";
import PermissionsTable from "shared/components/form/PermissionsTable";

function EditClient() {
  //if no id(clientid) => new client; if there is id => edit client
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ClientDto>({ defaultValues: {}, mode: "all" });
  const [loading, setLoading] = React.useState(true);
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const notificationMessageSuccess = id
    ? "Client has been updated."
    : "Client has been created.";
  const notificationMessageError = id
    ? "Client could not be updated."
    : "New client could not be created";

  const mutation = useMutation(
    [],
    (data: ClientDto) => {
      if (id) {
        return axios.post("clients/update", data);
      }
      return axios.post("clients/create", data);
    },
    {
      onSuccess: (resp) => {
        Store.addNotification({
          title: "Clients",
          message: notificationMessageSuccess,
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
        setShouldRedirect(true);
        fetchClient(resp.data.id);
      },
      onError: (resp) => {
        Store.addNotification({
          title: "Clients",
          message: notificationMessageError,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
            showIcon: true,
          },
        });
      },
    }
  );

  //gets
  const fetchClient = async (clientId) => {
    fetchGet(`clients/${clientId}`)
      .then((response) => response.data)
      .then((resData) => {
        reset(resData);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
    setLoading(false);
  };
  const fetchUsers = async (clientId) => {
    fetchGet(`clients/${clientId}/users`)
      .then((response) => response.data)
      .then((resData) => {
        setUsers(resData);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
      });
    setLoading(false);
  };

  //posts
  const postForm = async (data: ClientDto) => {
    //ensure permissions are integers and not booleans
    var permissions = Permissions();
    boolToInt(data, permissions);
    mutation.mutate(data);
  };

  //initialisation
  useEffect(() => {
    if (id) {
      fetchClient(id);
      fetchUsers(id);
    } else {
      setLoading(false);
    }
  }, []);

  //UI
  const userList = () => {
    return (
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-sm-12 col-md-9">
              <div className="card__title">
                <h5 className="bold-text">Users</h5>
                <h5 className="subhead">modify existing users from here</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="float-right">
                <Link to={"/pages/clients/" + id + "/newuser"}>
                  <button className="icon btn btn-success">
                    <AccountPlusIcon /> Add New User
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email (Username)</th>
                <th>Is Admin</th>
                <th>Is Active</th>
              </tr>
            </thead>
            <tbody>
              {users.map(function (val, i) {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={"/pages/editclientuser/" + val.id}>
                        {val.firstName + " " + val.lastName}
                      </Link>
                    </td>
                    <td>{val.email}</td>
                    <td>{val.isAdmin === 1 ? "Yes" : "No"}</td>
                    <td>{val.isDeleted === 1 ? "No" : "Yes"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  if (shouldRedirect && !id) {
    return <Navigate to="/pages/clients" />;
  }

  return (
    <div>
      <TitleWrapper title="Clients">
        {loading && <Spinner className="spinner" />}
        {!loading && (
          <StyledForm
            heading={id ? "Manage Client" : "New Client"}
            subHeading={
              (id
                ? "update client details using"
                : "create a new client using") + " the form below"
            }
            submit={handleSubmit(postForm)}
            isSaving={mutation.isLoading}
            errorMessage={
              mutation.isError &&
              mutation.error instanceof Error &&
              mutation.error.message
            }
          >
            <fieldset>
              <FormInput
                fieldName="name"
                title="Name"
                type="text"
                errors={errors}
                validation={{
                  minLength: {
                    value: 2,
                    message: "Must be 2 or more characters",
                  },
                  required: "This field is required",
                }}
                register={register}
              />
              <FormInput
                fieldName="clientCode"
                title="Client Code"
                type="text"
                errors={errors}
                validation={{
                  minLength: {
                    value: 2,
                    message: "Must be 2 or more characters",
                  },
                  required: "This field is required",
                }}
                register={register}
              />
              <FormInput
                fieldName="email"
                title="Email"
                type="text"
                errors={errors}
                validation={{
                  minLength: {
                    value: 8,
                    message: "Must be 8 or more characters",
                  },
                }}
                register={register}
              />
              <div>
                <p>
                  Client Search Permissions. Select the State and Searches users
                  in this group can request. <br />
                </p>
              </div>
            </fieldset>
            <Col md="6">
              <PermissionsTable
                register={register}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
              <button
                className="btn btn-primary account__btn account__btn--small"
                type="submit"
              >
                {id && "Update Client Details"}
                {!id && "Create New Client"}
              </button>
            </Col>
          </StyledForm>
        )}
      </TitleWrapper>
      {id && userList()}
    </div>
  );
}

export default EditClient;
