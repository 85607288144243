import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Card, CardBody, Col, Spinner } from "reactstrap";
import DataTable from "react-data-table-component";
import SearchIcon from "mdi-react/SearchIcon";
import PlusIcon from "mdi-react/PlusIcon";
import { Link } from "react-router-dom";
import { fetchGet } from "../../../../shared/utils";


const ClientsDataTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState('Id');
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');

  const fetchClients = async (page, size = perPage, column = sortColumn, direction = sortDirection, search = searchTerm) => {
    setLoading(true);

    fetchGet(`clients?page=${page}&pageSize=${size}&sortColumn=${column}&sortDirection=${direction}&search=${search}`)
      .then((response) => response.data)
      .then((resData) => {
        setData(resData.data);
        setTotalRows(resData.totalRows);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error getting data from server " + error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClients(1);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Id",
        sortable: true,
        sortField: 'Id',
        maxWidth: '300px',
        cell: row =>
          <Link to={"/pages/editclient/" + row.id}>
            {row.id}
          </Link>
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        maxWidth: '300px',
        sortField: 'Name'
      },
      {
        name: "Client Code",
        selector: "clientCode",
        sortable: true,
        maxWidth: '300px',
        sortField: 'ClientCode'
      },
    ]
  );

  const handlePageChange = page => {
    fetchClients(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchClients(page, newPerPage); 
  };

  const handleSort = async (column, sortDirection) => {
    setSortColumn(column.sortField);
    setSortDirection(sortDirection);
    fetchClients(1, perPage, column.sortField, sortDirection)
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchClients(1);
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-sm-12 col-md-9">
              <div className="card__title">
                <h5 className="bold-text">Client Search</h5>
                <h5 className="subhead">
                  find existing clients here
                </h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="float-right">
                <Link to="/pages/newclient">
                  <button className="icon btn btn-success">
                    <PlusIcon /> New Client
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div>
              <form
                className="form"
                onSubmit={(e) => {
                  handleSearch(e);
                }}
              >
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <SearchIcon />
                    </div>
                    <input
                      name="ClientSearch"
                      type="text"
                      placeholder="search via client name or client code"
                      value={searchTerm}
                      onChange={(e) =>
                        setSearchTerm(e.target.value)
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
            <div>
            <DataTable
              title="Clients"
              columns={columns}
              data={data}
              persistTableHead
              progressPending={loading}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onSort={handleSort}
              sortServer
            />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ClientsDataTable;
