import React, { useEffect } from 'react';
import {Spinner} from 'reactstrap';
import TitleWrapper from "../../../shared/components/TitleWrapper";
import StyledForm from "../../../shared/components/form/StyledForm";
import {useForm} from "react-hook-form";
import { useParams, useNavigate } from 'react-router';
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import {NewClaimDto, ThirdPartyOmissionOptionsReverse} from "../../../models/Claims/NewClaimDto";
import RadioOptionSelector from "../../../shared/components/form/RadioOptionSelector";
import { Store } from "react-notifications-component";

import FormInput from "../../../shared/components/form/FormInput";
import NewTranscript from "./NewTranscript";
import NewFieldCall from "./NewFieldCall";
import NewSkipTrace from "./NewSkipTrace";
import NewPolice from "./NewPolice";
import NewRegistration from "./NewRegistration";
import NewLicence from "./NewLicence";
import NewFire from "./NewFire";
import { Col } from 'reactstrap';
import { fetchGet } from "../../../shared/utils";
import moment from 'moment';
import { resetWarningCache } from 'prop-types';


function NewTranscriptClaim() {
  
  const navigate = useNavigate();
  const {claimId, partyId, searchId, sType} = useParams();
  const {register, getValues, watch, handleSubmit, reset, setValue, formState: {errors}} = useForm<NewClaimDto>({defaultValues:{state:''}, mode: 'all'});
  const [searchTypePermissions, setSearchTypePermissions] = React.useState({});
  const [claim, setClaim] = React.useState(0);
  const [partyType, setPartyType] = React.useState('');
  const [disabledFields, setDisabledFields] = React.useState([]);
  const [cardTitle, setCardTitle] = React.useState('');
  const [formHeading, setFormHeading] = React.useState('');
  const [formSubHeading, setFormSubHeading] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [fetch, setFetch] = React.useState(0);
  const mutation = useMutation([], (data: NewClaimDto) => {
    if (partyId) {
      //user is trying to create a new search, send data to /claims/newsearch/{partyId}
      return axios.post(`/claims/newsearch/${partyId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    }
    else if (claimId) {
      //user is trying to create a new party, send data to /claims/{claimId}/newparty
      return axios.post(`/claims/${claimId}/newparty`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    }
    else{
      //user is trying to create a new claim, send data to /claims/create
    }
    return axios.post('/claims/create', data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  {
    onSuccess: (resp) => {
      //new claim created and file uploaded
      let notificationTitle = 'New Claim Created';
      if(claimId){
        notificationTitle = 'New Party Created';
      }
      else if(partyId){
        notificationTitle = 'New Search Created';
      }
      Store.addNotification({
        title: notificationTitle,
        message: <a className="NotificationLink" href={"/pages/editclaim/" + resp.data.id}>
                  <b>
                    <u>{resp.data.claimNumber}</u>
                  </b>
                </a>,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 0,
          showIcon: true,
        },
      });
      reset();
    },
    onError: (resp: AxiosError) => {
      Store.addNotification({
        title: "Claims",
        message: "New claim could not be created." + resp.response.data,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 0,
          showIcon: true,
        },
      });
    }
  })

  const postForm = (data: NewClaimDto) => {
    var processed;
    if (data.searchType==='Transcript') {
      if(data.incidentDate === "Invalid date") {
        data.incidentDate = "";
      }
      processed = {
        ...data,
        id: claim,
        state: 'NSW',
        thirdPartyAddress: {},
        incidentDate: data.incidentDate ? new Date(data.incidentDate).toISOString() : "",
        skipTrace: {},
        registration: {},
        police:{},
        licence:{},
        fieldCall:{},
        fire:{},
        transcript: {
          ...data.transcript,
          audioFileUrl: (data.transcript.audioFileUrl || [])[0] || "",
          textFileUrl: (data.transcript.textFileUrl || [])[0] || "",
        }
      }
    };
    if (data.searchType==='Skip Trace') {
      processed = {
        ...data,
        id: claim,
        searchType: "SkipTrace",
        transcript: {},
        registration: {},
        police:{},
        licence:{},
        fieldCall:{},
        fire:{},
        skipTrace: {
          ...data.skipTrace,
          priorReports: data.skipTrace.priorReports?data.skipTrace.priorReports[0]:null,
          otherDocuments: data.skipTrace.otherDocuments?data.skipTrace.otherDocuments[0]:null
        }
      }
    };
    if (data.searchType==='Registration') {
      processed = {
        ...data,
        id: claim,
        thirdPartyOmissionReason: ThirdPartyOmissionOptionsReverse[data.thirdPartyOmissionReason],
        thirdPartyAddress: {},
        transcript: {},
        skipTrace: {},
        police:{},
        licence:{},
        fieldCall:{},
        fire:{},
      }
    };
    if (data.searchType==='Police') {
      processed = {
        ...data,
        id: claim,
        thirdPartyAddress: {},
        incidentDate: data.incidentDate ? new Date(data.incidentDate).toISOString() : "",
        transcript: {},
        skipTrace: {},
        registration: {},
        licence:{},
        fieldCall:{},
        fire:{},
        police: {
          ...data.police,
          authorityLetter: data.police.authorityLetter?data.police.authorityLetter[0]:null
        }
      }
    };
    if (data.searchType==='Licence') {
      processed = {
        ...data,
        id: claim,
        thirdPartyOmissionReason:  ThirdPartyOmissionOptionsReverse[data.thirdPartyOmissionReason],
        thirdPartyAddress: {},
        transcript: {},
        skipTrace: {},
        registration: {},
        police:{},
        fieldCall:{},
        fire:{},
      }
    };
    if (data.searchType==='Field Call') {
      processed = {
        ...data,
        id: claim,
        searchType: "FieldCall",
        transcript: {},
        skipTrace: {},
        registration: {},
        police:{},
        licence:{},
        fire:{},
        fieldCall: {
          ...data.fieldCall,
          documentsToComplete: data.fieldCall.documentsToComplete?data.fieldCall.documentsToComplete[0]:null
        }
      }
    };
    if (data.searchType==='Fire') {
      if(data.incidentDate === "Invalid date") {
        data.incidentDate = "";
      }
      processed = {
        ...data,
        id: claim,
        incidentDate: data.incidentDate ? new Date(data.incidentDate).toISOString() : "",
        searchType: "Fire",
        transcript: {},
        skipTrace: {},
        registration: {},
        police:{},
        licence:{},
        fire: {
          ...data.fire,
          authorityLetter: data.fire.authorityLetter?data.fire.authorityLetter[0]:null
        },
      }
    }
    mutation.mutate(processed);
  };
  
  const getPermissions = () => {
    return axios.get('/users/GetByJWT2');
  }
  
  const getInnerObjectKeys = (obj: Object, prefix = "") => {
    var keys = [];
    for(var key in obj) {
      if(obj[key] !== null && typeof obj[key] === 'object') {
        keys.push(...getInnerObjectKeys(obj[key], (prefix ? prefix + "." : "") + key + "."));
      } else {
        keys.push(prefix + key);
      }
    }
    return keys;
  }

  const validateClaimFormUnique = async (claimNumber: string) => {
    if (!claimId && !partyId && !searchId) {
      let result=false;
      await axios.get('claims/claimnumber/'+claimNumber)
      .then((response) => {
        if(response.data.id !== 0) {
          result=false;
        }
        else {
          result=true;
        }
      })
      return result;
    }
    
    return true;
  }

  const fetchClaim = async () => {
    return fetchGet(`claims/${claimId}`)
      .then((response) => response.data);
  };

  const fetchClaimParty = async () => {
    const partyTypePromise = fetchGet(`claims/partytype/${partyId}`)
        .then((response) => response.data);

    const partyPromise = fetchGet(`claims/parties/${partyId}`)
      .then((response) => response.data);

    return Promise.all([partyTypePromise, partyPromise]).then(response => {
      const partyTypeResponse = response[0];
      const partyResponse = response[1];

      return {
        partyType: partyTypeResponse.partyType,
        parties: {
          claimNumber: partyResponse.claimNumber,
          insuredName: partyResponse.insuredName,
          state: partyResponse.state,
          insuredRegistration: partyResponse.insuredRegistration,
          incidentDetails: partyResponse.incidentDetails,
          incidentLocation: partyResponse.incidentLocation,
          incidentDate: moment(partyResponse.incidentDate).format('YYYY-MM-DD'),
          thirdPartyName: partyResponse.thirdPartyName,
          thirdPartyRegistration: partyResponse.thirdPartyRegistration,
        },
        claim: partyResponse.id
      }
    });
  };

  const fetchSearch = async () => {
    return fetchGet(`searches/type/${sType}/${searchId}`).then((response) => response.data);
  };

  const goToClaim = () => {
    navigate(`/pages/editclaim/${claim}`);
    return;
  }

  useEffect(() => {
    getPermissions()
      .then((response) => {
        const permissionsData = response.data;

        if (claimId) {
          fetchClaim()
            .then((response) => {
              setClaim(response.id);
              const defaultData = {
                claimNumber: response.claimNumber,
                insuredName: response.insuredName,
                state: response.state,
                insuredRegistration: response.insuredRegistration,
                incidentDetails: response.incidentDetails,
                incidentLocation: response.incidentLocation,
                incidentDate: moment(response.incidentDate).format('YYYY-MM-DD')
              };
              reset(defaultData);

              let toBeDisabled = ["claimNumber"];

              setDisabledFields(toBeDisabled);
              setFormHeading('Add a new party to claim - '+getValues('claimNumber'));
              setFormSubHeading('create a new party using the form below');
              permissionsData['police'] = [];

              ['transcript','fieldCall','skipTrace','police','registration','licence','fire'].forEach((item) => {
                if (item in permissionsData){
                  if (!permissionsData[item].includes(getValues('state'))) {
                    permissionsData[item]=[];
                  }
                }
              });

              setLoading(false);
            })
            .catch((error) => {
              console.log("error getting data from server " + error);
            });
        } else if (searchId) {
          fetchSearch()
            .then((response) => {
              console.log(response);
              setClaim(response.claimId);
              const defaultData = {
                ...response,
                searchType: response.searchType || sType || "", //TODO get searchType from backend TranscriptService.GetById()
                incidentDate: moment(response.incidentDate, 'DD/MM/YYYY HH:mm A').format('YYYY-MM-DD'),
              };
              reset(defaultData);
              setDisabledFields(getInnerObjectKeys(defaultData));
              setCardTitle('Searches');
              const partyName = getValues('partyName');
              setFormHeading(getValues('searchType') + ' Details ' + (partyName ? " - " + partyName : ""));
              setFormSubHeading('search details are below');
              setLoading(false);
            })
            .catch((error) => {
              console.log("error getting data from server " + error);
            });
        } else if (partyId) {
          fetchClaimParty().then(response => {
            setPartyType(response.partyType);
            setClaim(response.claim);
            reset(response.parties);

            let toBeDisabled = ["claimNumber"];

            setDisabledFields(toBeDisabled);
            setFormSubHeading('add a new search using the form below');

            if (response.partyType === 'Insured') {
              setFormHeading(`Add a new search for ${partyType} Party - ${getValues('insuredName')}`);
              permissionsData['fieldCall']=[];
              permissionsData['licence']=[];
              permissionsData['registration']=[];
              permissionsData['skipTrace']=[];
            } else {
              setFormHeading(`Add a new search for ${partyType} - ${getValues('thirdPartyName')}`);
              permissionsData['police']=[];
            }

            ['transcript','fieldCall','skipTrace','police','registration','licence','fire'].forEach((item) => {
              if (item in permissionsData){
                if (!permissionsData[item].includes(getValues('state'))) {
                  permissionsData[item]=[];
                }
              }
            });

            setLoading(false);
          });
        } else {
          setCardTitle('Claims');
          setFormHeading('New Claim');
          setFormSubHeading('Enter new claim');
          setLoading(false);
        }

        setSearchTypePermissions(permissionsData);
      }); 
  }, [fetch]);

  const permissionMap={
    'transcript': 'Transcript',
    'fieldCall': 'Field Call',
    'skipTrace': 'Skip Trace',
    'police': 'Police',
    'registration': 'Registration',
    'licence': 'Licence',
    'fire': 'Fire',
  }
  
  const searchType = watch("searchType");

  let isEditFormResult = false;
  if (searchId) {
    isEditFormResult=true;
  }
  
  return (
    <TitleWrapper title={cardTitle}>
      {loading && <Spinner className="spinner"/>}
      {!loading && <StyledForm heading={formHeading} subHeading={formSubHeading} submit={handleSubmit(postForm)}
                  isSaving={mutation.isLoading}
                  errorMessage={mutation.isError && mutation.error instanceof Error && mutation.error.message}>
        <fieldset disabled={searchId && disabledFields.length > 0} >
          <RadioOptionSelector 
            title="Search Type" 
            fieldName="searchType" 
            options={Object.keys(searchTypePermissions).reduce(function(result, key) {
              if(searchTypePermissions[key].length>0) {
                result.push(permissionMap[key]);
              }
              return result;
            }, [])} 
            register={register} 
            errors={errors} 
            validation={{
              required: "This field is required"
            }}
          />
          <FormInput 
            fieldName="claimNumber" 
            title="Claim Number" 
            type='text' 
            errors={errors} 
            validation={{
              validate: {validateClaimFormUnique: async (v:string) => await validateClaimFormUnique(v) || "Claim number must be unique"}, 
              minLength: {value: 2, message: "Must be 2 or more characters"}, 
              required: "This field is required"
            }} 
            register={register}
            disabled={disabledFields.includes('claimNumber')}
          />
          </fieldset>
          {searchType === 'Transcript' && searchTypePermissions['transcript'] && <NewTranscript errors={errors} register={register} disabledFields={disabledFields} getValues={getValues} isEditForm={isEditFormResult} reset={reset} setLoading={setLoading} setFetch={setFetch} fetch={fetch} watch={watch}/>}
          {searchType === 'Field Call' && searchTypePermissions['fieldCall'] && <NewFieldCall statePermissions={searchTypePermissions['fieldCall']} errors={errors} register={register} watch={watch} disabledFields={disabledFields} isEditForm={isEditFormResult}/>}
          {searchType === 'Skip Trace' && searchTypePermissions['skipTrace'] && <NewSkipTrace statePermissions={searchTypePermissions['skipTrace']} errors={errors} register={register} watch={watch} disabledFields={disabledFields} isEditForm={isEditFormResult}/>}
          {searchType === 'Police' && searchTypePermissions['police'] && <NewPolice statePermissions={searchTypePermissions['police']} errors={errors} register={register} watch={watch} disabledFields={disabledFields} />}
          {searchType === 'Registration' && searchTypePermissions['registration'] && <NewRegistration statePermissions={searchTypePermissions['registration']} setValue={setValue} errors={errors} register={register} watch={watch} disabledFields={disabledFields} />}
          {searchType === 'Licence' && searchTypePermissions['licence'] && <NewLicence statePermissions={searchTypePermissions['licence']} setValue={setValue} errors={errors} register={register} watch={watch} disabledFields={disabledFields} />}
          {searchType === 'Fire' && searchTypePermissions['fire'] && <NewFire statePermissions={searchTypePermissions['fire']} errors={errors} register={register} watch={watch} disabledFields={disabledFields} />}
          {!searchId && <Col md="6">
          <button
              className="btn btn-primary account__btn account__btn--small"
              type='submit'
          >
            {!claimId && !partyId && 'Create New Claim'}
            {claimId && 'Create New Party'}
            {partyId && 'Create New Search'}
          </button>
          </Col>}
        {searchId && <Col md="6">
          <button
              className="btn btn-primary account__btn account__btn--small"
              type="button" onClick={goToClaim}
          >Back To Claim</button>
          </Col>}
      </StyledForm>}
    </TitleWrapper>
  );
}

export default NewTranscriptClaim;
