import React from "react";
import ResetPasswordRequestForm from "./components/ResetPasswordRequestForm";

const logoPath = `${process.env.PUBLIC_URL}/img/inspector-logo.png`;

const ResetPasswordRequest = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
            <div className="account__logo">
              <img src={logoPath} alt="inspector logo" />
            </div>
        </div>
        <ResetPasswordRequestForm />
      </div>
    </div>
  </div>
);

export default ResetPasswordRequest;
