import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import jwt_decode from "jwt-decode";
import { logout } from "../../../shared/utils";
class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      ccsgVal: false,
      urVal: false,
    };
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  componentDidMount() {
    if (localStorage.getItem("jwt_access") !== null) {
      var decoded = jwt_decode(localStorage.getItem("jwt_access"));
      this.setState({
        ccsgVal: decoded.ccsg_val === "1" ? true : false,
        urVal: decoded.ur_val === "1" ? true : false,
      });
    }
  }

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarCategory title="Claims" icon="layers">
            <SidebarLink
              title="New Claim"
              route="/pages/newclaim"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Existing Claims"
              route="/pages/claimlist"
              onClick={this.hideSidebar}
            />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Settings" icon="cog">
            {this.state.ccsgVal && this.state.urVal ? (
              <SidebarLink
                title="Clients"
                route="/pages/clients"
                onClick={this.hideSidebar}
              />
            ) : null}

            {this.state.urVal ? (
              <SidebarLink
                title="Users"
                route="/pages/users"
                onClick={this.hideSidebar}
              />
            ) : null}

            {this.state.urVal ? (
              <SidebarLink
                title="User Groups"
                route="/pages/usergroups"
                onClick={this.hideSidebar}
              />
            ) : null}

            <SidebarLink
              title="My Details"
              route="/pages/userdetails"
              onClick={this.hideSidebar}
            />

            {this.state.ccsgVal && this.state.urVal ? (
              <SidebarLink
                title="General Settings"
                route="/pages/generalsettings"
                onClick={this.hideSidebar}
              />
            ) : null}
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
        
          <li className="sidebar__link">
            <a style={{textDecoration:"none"}}
              onClick={logout}
              className="sidebar__link-active"
            >
              <span className={`sidebar__link-icon lnr lnr-exit`} />
              <p className="sidebar__link-title">
                Log Out
              </p>
            </a>
          </li>
        
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
