import React from "react";
// import { Link } from "react-router-dom";
import EmailConfirmationForm from "./components/EmailConfirmationForm";

const EmailConfirmation = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            Welcome to
            <span className="account__logo">
              {" "}
              <span className="account__logo-accent">CCSG Online</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">CCSG Legal Pty Ltd</h4>
        </div>
        <p>
          Set your password using the form below. Password requirements are:
        </p>{" "}
        <br></br>
        <ul>
          <li>minimum 10 characters</li>
          <li>
            include a mixture of upper and lower case letters, number and
            symbols
          </li>
          <li>can not be the same as your email</li>
        </ul>
        <br></br>
        <EmailConfirmationForm />
      </div>
    </div>
  </div>
);

export default EmailConfirmation;
